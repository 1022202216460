<template>
    <ContentLayout>
        <div class="relative h-[75vh]">
            <PodcasterHeader
                :podcaster="podcaster"
                :total-podcasts="totalPodcasts"
                :selected-category="selectedCategory || undefined"
                :is-seemore-view="isSeemoreView"
                :invisible-podcasters-count="invisiblePodcastersCount"
                :is-loading-follow-state="isLoadingFollowState"
            />

            <div v-if="!isStartRoute" class="mt-8 mb-8">
                <div class="sticky bottom-0 bg-white">
                    <ConversationStartersList class="mt-4" :starters="podcaster?.conversation_starters ?? []" @selected="submitQuery" />
                </div>
            </div>

            <div class="mt-4 mb-32 grid grid-cols-1 gap-x-6 gap-y-3 md:grid-cols-2 xl:grid-cols-3" ref="scroller">
                <div v-for="podcast in filteredPodcasts" :key="podcast.id">
                    <div class="relative mx-auto w-full max-w-4xl overflow-hidden rounded-lg shadow-lg cursor-pointer"
                        @click="goToPodcastPage(podcast.external_id)">
                        <div class="h-70">
                            <img
                                :src="`https://img.youtube.com/vi/${podcast.external_id}/maxresdefault.jpg`"
                                :alt="podcast.title"
                                class="w-full h-full object-cover"
                            />
                            <div class="absolute inset-0 flex items-center justify-center">
                                <YoutubeIcon class="h-12 w-12 fill-current text-red-600" />
                            </div>
                        </div>
                    </div>
                    <div class="relative flex items-center overflow-hidden">
                        <div class="flex-grow p-2" style="max-width: calc(100% - 1.5rem)">
                            <p class="truncate text-xs font-semibold text-gray-700">
                                {{ new Date(podcast.date)?.toLocaleDateString() }}
                            </p>
                            <div class="truncate text-sm font-semibold text-gray-800 sm:text-base" v-html="podcast.title"></div>
                        </div>
                        <div class="absolute right-2 top-1/2 -translate-y-1/2 transform">
                            <YoutubeIcon class="h-6 w-6 fill-current text-red-600" />
                        </div>
                    </div>
                </div>
                <div ref="endOfScroller"></div>
                <FailedMessage v-if="scrollState === ScrollState.Failed">
                    We couldn't process your query due to an internal error. Please try again later.
                </FailedMessage>
            </div>

            <div class="sticky bottom-0 leading-6">
                <div class="bg-gradient-to-b from-white/90 to-white">
                    <form @submit.prevent="submit" class="relative w-full max-w-full text-black md:mx-auto">
                        <SearchInput
                            v-model="form.query"
                            @submit="submit"
                            :disabled="isWaiting"
                            :placeholder="placeholder"
                            :error="errors.hasOwnProperty('question')"
                        />
                    </form>
                    <div class="relative py-3 text-center text-xs leading-4 text-gray-600 md:px-16 md:py-4">
                        <span class="text-gray-600">
                            Clipfinder.com knows all the podcasts until
                            <time class="italic" datetime="{{ trainingDate }}">{{ trainingDate.toLocaleDateString() }}</time>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </ContentLayout>
</template>

<script setup lang="ts">
import { onMounted, computed, ref, onBeforeMount, watch, reactive, inject } from 'vue'
import { storeToRefs } from 'pinia'
import { useInfiniteScroll, OverscrollDirection } from '@/Composables/useScrollEvents'
import type { OverscrollOptions } from '@/Composables/useScrollEvents'
import { useMotion } from '@vueuse/motion'
import { router } from '@inertiajs/vue3'
import ContentLayout from '@/Layouts/ContentLayout.vue'
import FailedMessage from '@/Components/FailedMessage.vue'
import YoutubeIcon from '@/Icons/YoutubeIcon.vue'
import 'vue-lite-youtube-embed/style.css'
import { ScrollState, useConversationStore } from '@/Stores/conversation'
import { usePodcasterStore } from '@/Stores/podcaster'
import { definePodcastStore } from '@/Stores/podcast'
import { useCategoryStore } from '@/Stores/category'
import type { CategoryResource, ClipResource, PodcasterResource, ConversationStarterResource } from '@/types'
import PodcasterHeader from '@/Components/PodcasterHeader.vue'
import ConversationStartersList from '@/Pages/Podcaster/ConversationStartersList.vue'
import { useUserStore, defineUserStore } from '@/Stores/user'
import SearchInput from '@/Components/SearchInput.vue'

const route = inject<any>('route')

const props = defineProps<{
    podcaster: PodcasterResource
    conversationStarters?: ConversationStarterResource[]
    category?: CategoryResource
    clips?: ClipResource
    isSeemoreView?: boolean
    scrollTo?: string
    starters?: ConversationStarterResource[]
}>()

defineUserStore()
const podcasterStore = usePodcasterStore()
const conversationStore = useConversationStore()
const categoryStore = useCategoryStore()
const userStore = useUserStore()
const { selectedPodcasters, trainingDate } = storeToRefs(podcasterStore)
const { scrollState, shared } = storeToRefs(conversationStore)
const { selectedCategory } = storeToRefs(categoryStore)

const store = definePodcastStore(props.podcaster)
const { podcasts, totalPodcasts } = storeToRefs(store)

const scroller = ref<HTMLElement>()
const endOfScroller = ref<HTMLElement>()
const $podcasterEL = ref<HTMLElement>()
const searchQuery = ref('')
const { loggedIn } = storeToRefs(userStore)
const isLoadingFollowState = ref<boolean>(false)

const isStartRoute = computed(() => {
    return route().current() === 'clipfinder.conversations.start'
})

const goToPodcastPage = (videoId: string) => {
    try {
        if (!props.podcaster?.slug) {
            return;
        }

        const url = route('clipfinder.podcaster.podcasts.video', {
            podcaster: props.podcaster.slug,
            videoId: videoId
        });

        if (!url) {
            return;
        }

        router.visit(url, {
            preserveScroll: true,
            preserveState: true,
            replace: false
        });
    } catch (e: any) {
        ScrollState.Failed
    }
}

const invisiblePodcastersCount = computed(() => Math.max(0, selectedPodcasters.value.length - 6))
const form = reactive({
    query: '',
})

const placeholder = computed(() => {
    return podcasterStore.selectedPodcasters.length === 1
        ? `Ask a question to ${podcasterStore.selectedPodcasters[0].name}...`
        : `Ask a question...`
})

const errors = ref<{ string: string[] }[]>([])

const { sendMessage } = conversationStore

const emit = defineEmits(['submit'])

const isWaiting = computed(() => scrollState.value === ScrollState.Requesting)

const submit = async () => {
    if (isWaiting.value || !props.podcaster) {
        return
    }

    try {
        const question = form.query
        form.query = ''

        if (props.podcaster) {
            podcasterStore.selectPodcasters([props.podcaster.id])
        }

        const conversation = await sendMessage(question, true)

        if (conversation) {
            router.visit(route('clipfinder.conversations.show', [conversation]), {
                preserveScroll: true,
                preserveState: true,
                replace: false
            })
        }

        emit('submit', question)
    } catch (e: any) {
        errors.value = e.errors || []
    }
}

const submitQuery = async (question: string) => {
    if (!props.podcaster) return

    try {
        store.setFailed(false)
        podcasterStore.selectPodcasters([props.podcaster.id])

        const conversation = await conversationStore.sendMessage(question, true)

        if (conversation) {
            router.visit(route('clipfinder.conversations.show', [conversation]), {
                preserveScroll: true,
                preserveState: true,
                replace: false
            })
            scroller.value?.scrollIntoView({ behavior: 'smooth' })
        }
    } catch (e: any) {
        store.setFailed(true)
    }
}

const filteredPodcasts = computed(() => {
    if (!searchQuery.value) {
        return [...new Map(podcasts.value.map(podcast => [podcast.id, podcast])).values()]
    }

    const query = searchQuery.value.toLowerCase()
    return [...new Map(
        podcasts.value
            .filter(podcast =>
                podcast.title.toLowerCase().includes(query) ||
                podcast.description?.toLowerCase().includes(query)
            )
            .map(podcast => [podcast.id, podcast])
    ).values()]
})

onBeforeMount(() => {
    if (props.podcaster) {
        store.setPodcaster(props.podcaster)
    }
})

async function loadFollowedPodcasters() {
    if (loggedIn.value && props.podcaster?.id) {
        isLoadingFollowState.value = true;
        await podcasterStore.fetchFollowedPodcasters();
        isLoadingFollowState.value = false;
    }
}

watch(
  () => props.podcaster?.id,
  async (newId) => {
    if (newId && loggedIn.value) {
      isLoadingFollowState.value = true;
      await podcasterStore.fetchFollowedPodcasters();

      // Correct state sync after refresh
      const isFollowed = podcasterStore.followedPodcasters.some(p => p.id === newId);
      podcasterStore.updatePodcasterFollowStatus(newId, isFollowed);
      isLoadingFollowState.value = false;
    }
  }
);

onMounted(async () => {
    if (!props.podcaster) return

    if (loggedIn.value && props.podcaster?.id) {
        isLoadingFollowState.value = true;
        await loadFollowedPodcasters();
        isLoadingFollowState.value = false;
    }

    podcasterStore.selectPodcasters([props.podcaster.id])

    useInfiniteScroll(scroller.value, endOfScroller.value, async () => {
        if (podcasts.value.length < totalPodcasts.value) {
            await store.loadMorePodcasts()
        }
    }, {
        direction: OverscrollDirection.bottom,
        rootMargin: '300px',
        threshold: 0.1
    } as OverscrollOptions)

    if (shared.value && props.scrollTo) {
        const $scrollToEl = document.getElementById('clip_' + props.scrollTo)
        $scrollToEl?.scrollIntoView()
    }
})

watch(
    () => podcasterStore.followedPodcasters,
    () => {
        // Force refresh of podcaster selection when following state changes
        if (props.podcaster) {
            podcasterStore.selectPodcasters([props.podcaster.id]);

            // Update follow status when followedPodcasters changes
            const isFollowed = podcasterStore.followedPodcasters.some(p => p.id === props.podcaster.id);
            podcasterStore.updatePodcasterFollowStatus(props.podcaster.id, isFollowed);
        }
    },
    { deep: true }
)

useMotion($podcasterEL, {
    initial: { opacity: 0, y: -10 },
    enter: { opacity: 1, y: 0, transition: { delay: 0, duration: 500 } },
    leave: { opacity: 0, y: -10, transition: { delay: 0, duration: 200 } },
})
</script>
