<template>
    <div @click="navigateToPodcaster(podcaster)" class="group flex shrink-0 transform flex-col transition duration-300 hover:cursor-pointer">
        <div
            class="mx-auto size-24 rounded-full bg-cover bg-center shadow-lg ring-1 ring-black/5 hover:shadow-xl sm:size-40 md:size-32 lg:size-40 xl:size-40"
            :style="backgroundImageStyle"
        ></div>
        <div class="mt-4 text-center">
            <p class="truncate text-sm font-bold">{{ podcaster.name }}</p>
            <p class="mt-px truncate text-xs text-gray-700">{{ podcaster.description }}</p>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import type { PodcasterResource } from '@/types'
import { usePodcasterStore } from '@/Stores/podcaster'

const props = defineProps<{
    podcaster: PodcasterResource
}>()

const podcasterStore = usePodcasterStore()
const { navigateToPodcaster } = podcasterStore

const backgroundImageStyle = computed(() => ({
    backgroundImage: `url(${props.podcaster.webp_picture_url})`,
}))
</script>
