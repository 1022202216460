<template>
    <Layout>
        <div class="relative z-0 flex h-[100svh] w-full overflow-hidden">
            <ChatSidebar @startNewChat="startNewChat" class="max-w-xs" />
            <div class="relative z-10 h-full max-w-full flex-1 overflow-y-auto bg-white shadow-[0_25px_50px_-12px_black] scrollbar-thin">
                <ChatHeader />
                <main class="transition-width relative w-full flex-1">
                    <ChatSidebarToggle />

                    <PodcastSelectorSlideOver ref="podcastSelectorSlideOver" />

                    <div role="presentation" class="mx-auto max-w-5xl px-4" tabindex="0">
                        <div class="mt-8 lg:mt-12">
                            <slot />
                        </div>
                    </div>
                </main>
            </div>
        </div>
        <ChatSlideOver />
    </Layout>
</template>

<script setup lang="ts">
import Layout from '@/Layouts/Layout.vue'
import ChatSidebar from '@/Layouts/Partials/ChatSidebar.vue'
import ChatSidebarToggle from '@/Layouts/Partials/ChatSidebarToggle.vue'
import Breadcrumbs from '@/Components/Breadcrumbs.vue'
import PageHeader from '@/Layouts/Partials/PageHeader.vue'
import PodcastSelectorSlideOver from '@/Layouts/Partials/PodcastSelectorSlideOver.vue'
import ChatSlideOver from '@/Layouts/Partials/ChatSlideOver.vue'
import PodcasterSelectbox from '@/Components/PodcasterSelectbox.vue'

import { ref } from 'vue'
import ChatHeader from '@/Components/ChatHeader.vue'

var podcastSelectorSlideOver = ref(null)

const startNewChat = () => {
    podcastSelectorSlideOver.value.startNewChat(true)
}
</script>
