<template>
    <div
        ref="sidebar"
        class="absolute z-50 h-full flex-shrink-0 bg-gray-800 transition-all duration-500 md:relative md:z-auto md:duration-300"
        :class="{ 'w-full md:w-[340px]': open, 'invisible translate-x-[-100%] md:w-0 md:translate-x-0': !open }"
    >
        <div class="flex h-full min-h-0 flex-col overflow-x-hidden">
            <div class="flex h-full min-h-0 flex-col transition-opacity"
                 :class="{ 'md:opacity-50': hover, 'opacity-100': !hover }">
                <nav class="flex h-full w-full flex-col px-3 pb-4 pt-1" aria-label="Chat history">
                    <div
                        class="-mr-2 flex-1 flex-col overflow-y-auto pr-2 transition-opacity duration-500 scrollbar-hide">
                        <div class="sticky left-0 right-0 top-0 z-20 bg-gray-800 pt-2">
                            <div class="pb-0.5 last:pb-0 flex items-center gap-2" tabindex="0" data-projection-id="7">
                                <Link
                                    @mouseover="hoveringLogo = true"
                                    @mouseleave="hoveringLogo = false"
                                    @click="handleLogoClick"
                                    class="flex h-10 cursor-pointer items-center gap-2 rounded-lg px-2 font-medium"
                                    :href="route('clipfinder.home')"
                                    :only="['user']"
                                >
                                    <PodcastclipfinderIcon :animate="hoveringLogo"
                                                           class="-mx-2 h-8 shrink-0 fill-current text-white" />
                                    <span class="ml-1 text-xl font-black tracking-wide text-white">Clipfinder</span>
                                </Link>

                                <div class="flex-1"></div>

                                <button
                                    @click="startNewChat(null)"
                                    role="button"
                                    id="new-chat-button"
                                    aria-label="Create New Chat Button"
                                    class="ml-3 rounded-md bg-gray px-1 py-2 text-sm font-semibold text-primary-700 shadow-sm hover:bg-white hover:text-gray-800 focus:outline-none focus:ring-2 focus:ring-white"
                                >
                                    <PencilSquareIcon class="h-5 w-10" />
                                </button>
                            </div>
                        </div>

                        <div v-if="loggedIn">
                            <!-- Follow podcaster -->
                            <div v-if="followedPodcasters?.length" class="mt-4 text-sm text-gray-200">
                                <div v-if="didInitialLoad">Loading...</div>
                                <template v-else>
                                    <h3 class="px-3 text-xs font-semibold text-gray-500">Following</h3>
                                    <ul class="mt-2 space-y-1">
                                        <li v-for="podcaster in displayedPodcasters" :key="podcaster.id" class="px-3 py-2 rounded-md hover:bg-gray-700">
                                            <div @click="startNewChat(podcaster)" class="flex items-center cursor-pointer" @click.prevent="loadPreviousQuestion(podcaster)">
                                                <picture class="inline-block size-6 shrink-0 rounded-full">
                                                    <source
                                                        class="size-6 rounded-full"
                                                        :srcset="podcaster.webp_picture_url"
                                                        type="image/webp"
                                                        height="24"
                                                        width="24"
                                                    />
                                                    <img
                                                        class="size-6 rounded-full"
                                                        :src="podcaster.picture_url"
                                                        :alt="podcaster.name"
                                                        height="24"
                                                        width="24"
                                                        loading="lazy"
                                                    />
                                                </picture>
                                                <span class="ml-3 text-sm">{{ podcaster.name }}</span>
                                                <span class="ml-auto flex items-center justify-center w-6 h-6 bg-gray-600 rounded-full text-xs text-white">{{ podcaster.podcasts_count }}</span>
                                            </div>
                                        </li>
                                    </ul>
                                    <div v-if="(followedPodcasters?.length ?? 0) > 5" class="mt-2 px-3">
                                        <button @click="toggleShowMore" class="text-xs text-gray-400 hover:text-white">
                                            {{ showMore ? 'Show Less' : 'Show More' }}
                                        </button>
                                    </div>
                                </template>
                            </div>
                        </div>

                        <div class="flex flex-col gap-2 pb-2 text-sm text-gray-800 dark:text-gray-100">
                            <div class="relative">
                                <div
                                    :class="{ 'pointer-events-none opacity-50': !loggedIn }"
                                    v-for="group in groupedConversations"
                                    class="relative mt-5 text-white"
                                    :key="group.name"
                                >
                                    <div v-if="group.conversations.length > 0">
                                        <div>
                                            <h3
                                                class="h-9 overflow-hidden text-ellipsis break-all px-2 px-3 pb-2 pt-3 text-xs font-medium text-gray-500"
                                            >
                                                {{ group.name }}
                                            </h3>
                                        </div>
                                        <ol>
                                            <ConversationHistory
                                                v-for="conversation in group.conversations"
                                                :conversation="conversation"
                                                :active="conversation.id === conversationId"
                                                :key="conversation.id"
                                            />
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="border-token-border-light flex flex-col gap-1 border-t border-t-0 border-black/20 pt-2 empty:hidden dark:border-white/20"
                    >
                        <div class="flex w-full items-center">
                            <div class="grow">
                                <Menu as="div" v-if="loggedIn" class="group relative inline-block w-full text-left">
                                    <MenuButton
                                        class="flex w-full items-center gap-2 gap-3 rounded-lg p-1 p-3 text-sm hover:bg-gray-100 hover:bg-gray-800"
                                        type="button"
                                    >
                                        <div class="flex-shrink-0">
                                            <img
                                                class="h-8 w-8 rounded-full bg-white ring ring-white"
                                                width="32"
                                                height="32"
                                                :src="user?.avatar_url || '/img/avatar.png'"
                                                :alt="(user?.name || 'Guest')+' avatar'"
                                                loading="lazy"
                                            />
                                        </div>
                                        <div
                                            class="relative -top-px grow -space-y-px overflow-hidden text-ellipsis whitespace-nowrap text-left text-gray-100"
                                        >
                                            <div class="font-semibold">
                                                <span>{{ user?.name }}</span>
                                            </div>
                                        </div>
                                        <div>
                                            <ChevronDownIcon class="h-5 w-5 text-white" aria-hidden="true" />
                                        </div>

                                        <transition
                                            enter-active-class="transition duration-100 ease-out"
                                            enter-from-class="transform scale-95 opacity-0"
                                            enter-to-class="transform scale-100 opacity-100"
                                            leave-active-class="transition duration-75 ease-in"
                                            leave-from-class="transform scale-100 opacity-100"
                                            leave-to-class="transform scale-95 opacity-0"
                                        >
                                            <MenuItems
                                                class="absolute bottom-16 left-0 mt-2 w-full origin-bottom-left divide-y divide-gray-100 rounded-md bg-white/80 shadow-lg ring-1 ring-black/5 backdrop-blur focus:outline-none"
                                            >
                                                <div class="px-1 py-1">
                                                    <!--                                                    <MenuItem>-->
                                                    <!--                                                        <button-->
                                                    <!--                                                            class="group flex w-full items-center rounded-md px-2 py-2 text-sm font-medium text-gray-800"-->
                                                    <!--                                                        >-->
                                                    <!--                                                            <UserIcon class="mr-2 h-5 w-5" aria-hidden="true" />-->
                                                    <!--                                                            Profile-->
                                                    <!--                                                        </button>-->
                                                    <!--                                                    </MenuItem>-->
                                                    <MenuItem>
                                                        <Link
                                                            href="/logout"
                                                            method="delete"
                                                            type="button"
                                                            class="group flex w-full items-center rounded-md px-2 py-2 text-sm font-medium text-gray-800"
                                                        >
                                                            <ArrowRightOnRectangleIcon class="mr-2 h-5 w-5" aria-hidden="true" />
                                                            Logout
                                                        </Link>
                                                    </MenuItem>
                                                </div>
                                            </MenuItems>
                                        </transition>
                                    </MenuButton>
                                </Menu>
                                <div v-else class="borders rounded-lg bg-white/50 px-5 py-4 text-center shadow backdrop-blur">
                                    <div>
                                        <PodcastclipfinderIcon class="mx-auto h-8" />
                                    </div>
                                    <div class="mt-3 text-base font-semibold">Create an account</div>
                                    <span> To see your history and get access to more podcasters </span>

                                    <div class="mt-5 space-y-2">
                                        <GoogleLoginButton />
                                        <EmailLoginButton />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import { Link, router } from '@inertiajs/vue3'
import { useMenuStore } from '@/Stores/menu'
import { useConversationStore } from '@/Stores/conversation'
import { storeToRefs } from 'pinia'
import ConversationHistory from '@/Components/ConversationHistory.vue'
import PodcastclipfinderIcon from '@/Icons/PodcastclipfinderIcon.vue'
import { computed, ref, onMounted, inject, watch } from 'vue'
import type { ClipFinderConversationResource, PodcasterResource } from '@/types'
import { useUserStore } from '@/Stores/user'
import GoogleLoginButton from '@/Components/GoogleLoginButton.vue'
import EmailLoginButton from '@/Components/EmailLoginButton.vue'
import { ChevronDownIcon, ArrowRightOnRectangleIcon } from '@heroicons/vue/24/outline'
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'
import { onClickOutside } from '@vueuse/core'
import { usePodcasterStore } from '@/Stores/podcaster'
import { PencilSquareIcon } from '@heroicons/vue/24/solid'

const route = inject('route')

const userStore = useUserStore()
const menuStore = useMenuStore()
const conversationStore = useConversationStore()
const podcasterStore = usePodcasterStore()

const { toggle, close } = menuStore
const { open, hover, mobile } = storeToRefs(menuStore)
const { conversationId, conversations } = storeToRefs(conversationStore)

const { user, loggedIn } = storeToRefs(userStore)

const hoveringLogo = ref(false)
const sidebar = ref(null)
const { followedPodcasters, isLoading } = storeToRefs(podcasterStore);
const didInitialLoad = ref(!isLoading.value)

watch(isLoading, (newValue, oldValue) => {
    if (newValue && !oldValue) {
        didInitialLoad.value = false;
    }
})

const showMore = ref(false)

const displayedPodcasters = computed(() => {
    if (!followedPodcasters.value?.length) return [];
    // Create a new array to force reactivity
    let temp = podcasterStore.podcasters.filter(podcaster => {
        const followedPodcaster = followedPodcasters.value.find(followed => followed.id === podcaster.id)
        if (followedPodcaster) {
            podcaster.questions_count = followedPodcaster.questions_count
        }
        return !!followedPodcaster
    })
    const podcasters = [...temp];

    return showMore.value ? podcasters : podcasters.slice(0, 5);
});

const loadPreviousQuestion = async (podcaster: PodcasterResource) => {
    await podcasterStore.fetchPodcasterQuestions(podcaster)
    router.visit(`/${podcaster.slug}`)
}

const toggleShowMore = () => {
    showMore.value = !showMore.value
}

if (mobile.value) {
    onClickOutside(sidebar, () => {
        if (!open.value) {
            return
        }

        close()
    })
}

const handleLogoClick = () => {
    if (mobile.value) {
        close()
        router.visit(route('clipfinder.home'))
    }
}

onMounted(async () => {
    if (loggedIn.value) {
        await Promise.all([
            conversationStore.fetchHistory(),
            podcasterStore.fetchFollowedPodcasters(),
        ])
    }
})

watch(
    followedPodcasters,
    (newValue, oldValue) => {
        // Only fetch if we have no data AND we're logged in AND not already loading
        if (!newValue && !isLoading.value && loggedIn.value) {
            podcasterStore.fetchFollowedPodcasters();
            return;
        }

        // Handle unfollowed podcaster
        if (oldValue?.length) {
            // Find which podcaster was unfollowed
            const unfollowedPodcaster = oldValue.find(old =>
                !newValue?.some(current => current.id === old.id)
            );

            // Only clear selected podcaster questions, don't affect the questions count
            if (unfollowedPodcaster &&
                podcasterStore.selectedPodcasterWithQuestions?.id === unfollowedPodcaster.id) {
                podcasterStore.selectedPodcasterWithQuestions = null;
            }
        }
    },
    { deep: true }
)

const groupedConversations = computed(() => {
    const now = new Date()
    const today = now.setHours(0, 0, 0, 0)
    const yesterday = new Date(today).setDate(now.getDate() - 1)
    const last7Days = new Date(today).setDate(now.getDate() - 7)

    const grouped: {
        [key: string]: {
            name: string
            conversations: ClipFinderConversationResource[]
        }
    } = {
        today: {
            name: 'Today',
            conversations: [],
        },
        yesterday: {
            name: 'Yesterday',
            conversations: [],
        },
        previous_7_days: {
            name: 'Previous 7 Days',
            conversations: [],
        },
        older: {
            name: 'Older',
            conversations: [],
        },
    }

    conversations.value.forEach((conversation: ClipFinderConversationResource) => {
        const updatedDate = new Date(conversation.updated_at).setHours(0, 0, 0, 0)

        if (updatedDate === today) {
            grouped.today.conversations.push(conversation)
        } else if (updatedDate === yesterday) {
            grouped.yesterday.conversations.push(conversation)
        } else if (updatedDate >= last7Days) {
            grouped.previous_7_days.conversations.push(conversation)
        } else {
            grouped.older.conversations.push(conversation)
        }
    })

    return grouped
})

const $emit = defineEmits(['startNewChat'])

const startNewChat = (podcaster: PodcasterResource) => {
    if (mobile.value) close()

    if (podcaster) {
        router.visit(route('clipfinder.podcasters.show', [podcaster.slug]))
    } else {
        $emit('startNewChat')
    }
}
</script>
