<template>
    <li
        class="inline-flex flex-nowrap items-center justify-between rounded-full bg-transparent px-3 py-2"
    >
        <div class="flex items-center">
            <picture @click="podcastIconClicked"
                     class="mr-2 inline-block h-6 w-6 rounded-full hover:cursor-pointer opacity-100">
                <source class="h-6 w-6 rounded-full" :srcset="podcaster.webp_picture_url" type="image/webp" height="24"
                        width="24" />
                <img loading="lazy" class="h-6 w-6 rounded-full" :src="podcaster.picture_url" :alt="podcaster.name" height="24"
                     width="24" />
            </picture>

            <div
                class="whitespace-nowrap text-sm font-medium text-gray-800 hover:opacity-100 hover:cursor-pointer"
                @mouseenter="hover = true"
                @mouseleave="hover = false"
                @click="toggle"
                :class="{
                    'opacity-40': !selected && (!hover || mobile)
                }"
            >
                {{ podcaster.name }}
            </div>
        </div>

        <div @click="toggle" class="ml-2" @mouseenter="hover = true" @mouseleave="hover = false">
            <button
                v-if="!selected"
                :id="'add-'+podcaster.slug"
                class="inline-flex h-5 w-5 cursor-pointer items-center justify-center rounded-full bg-green-200 text-green-800 hover:opacity-100 hover:cursor-pointer hover:bg-green-300 focus:outline-none focus:ring-2 focus:ring-gray-400"
                :class="{
                    'opacity-40': !selected && (!hover || mobile)
                }"
                type="button"
            >
                <PlusIcon class="h-3 w-3 flex-shrink-0" />
            </button>
            <button
                v-else-if="selected"
                :id="'remove-'+podcaster.slug"
                class="inline-flex h-5 w-5 cursor-pointer items-center justify-center rounded-full bg-red-100 text-red-800 hover:opacity-100 hover:cursor-pointer hover:bg-red-300 focus:outline-none focus:ring-2 focus:ring-red-400"
                :class="{
                    'opacity-40': !selected && (!hover || mobile)
                }"
                type="button"
            >
                <XMarkIcon class="h-3 w-3 flex-shrink-0" />
            </button>
            <button :id="'empty-'+podcaster.slug" v-else class="h-2 w-2"></button>
        </div>
    </li>
</template>
<script setup lang="ts">
import type { PodcasterResource } from '@/types'
import { XMarkIcon, PlusIcon } from '@heroicons/vue/24/outline'
import { ref } from 'vue'

const props = defineProps<{
    podcaster: PodcasterResource
    selected: boolean
    mobile: boolean
}>()

const hover = ref(false)

const podcastIconClicked = () => {
    $emit('startNewChat', props.podcaster)
}

const $emit = defineEmits(['add', 'remove', 'startNewChat'])

const toggle = () => {
    if (!props.selected) {
        $emit('add', props.podcaster)
        return
    }

    $emit('remove', props.podcaster)
}
</script>
