<template>
    <div>
        <div
            class="left-0 top-1/2 z-40 hidden -translate-y-1/2 rotate-0 transition-transform md:fixed md:block"
            :class="{ 'translate-x-[340px] rotate-0': open, 'translate-x-0 rotate-180': !open }"
        >
            <button class="group" @click="toggle" @mouseover="onHover(true)" @mouseleave="onHover(false)">
                <span>
                    <div class="flex h-[72px] w-8 items-center justify-center opacity-25 group-hover:opacity-100">
                        <div class="flex h-6 w-6 flex-col items-center">
                            <div
                                class="h-5 w-1 translate-y-[0.15rem] rotate-0 rounded-full bg-black transition-transform group-hover:rotate-[15deg]"
                            ></div>
                            <div
                                class="-mt-1 h-5 w-1 translate-y-[0.15rem] rotate-0 rounded-full bg-black transition-transform group-hover:rotate-[-15deg]"
                            ></div>
                        </div>
                    </div>
                    <span class="sr-only">Close sidebar</span>
                </span>
            </button>
        </div>
    </div>
</template>
<script setup lang="ts">
import { useMenuStore } from '@/Stores/menu'
import { storeToRefs } from 'pinia'

const store = useMenuStore()
const { toggle, onHover } = store
const { open } = storeToRefs(store)
</script>
