<template>
    <Head v-if="messages.length === 0" :title="'Start a conversation'" />
    <div class="mx-2 flex-1 overflow-hidden md:mx-0">
        <div
            class="absolute left-0 top-[10rem] -z-10 mt-96 origin-top-left translate-y-40 -rotate-90 transform-gpu opacity-20 blur-3xl sm:left-[40%] sm:-ml-96 sm:-mt-10 sm:translate-y-0 sm:rotate-0 sm:transform-gpu sm:opacity-40"
            aria-hidden="true"
            style="contain: size layout; width: 1154px; height: 678px;"
        >
            <div
                class="aspect-[1154/678] w-[72.125rem] bg-gradient-to-br from-primary-500/50 to-primary"
                style="
                    clip-path: polygon(
                        100% 38.5%,
                        82.6% 100%,
                        60.2% 37.7%,
                        52.4% 32.1%,
                        47.5% 41.8%,
                        45.2% 65.6%,
                        27.5% 23.4%,
                        0.1% 35.3%,
                        17.9% 0%,
                        27.7% 23.4%,
                        76.2% 2.5%,
                        74.2% 56%,
                        100% 38.5%
                    );
                "
            ></div>
        </div>

        <div class="relative h-[85vh]">
            <div class="h-full w-full overflow-y-auto" id="messages" ref="scroller">
                <div class="flex h-full flex-col pb-9 text-sm dark:bg-transparent">
                    <div ref="$podcasterEL" v-if="messages.length === 0" class="flex h-full flex-col items-center justify-center">
                        <div class="mx-auto mt-8 w-full max-w-4xl px-4 text-center sm:px-6 lg:-mt-20 lg:px-8">
                            <div class="mb-8 flex items-center justify-center lg:mb-16">
                                <div v-if="selectedPodcasters.length > 0" class="flex -space-x-14 md:-space-x-24">
                                    <Link
                                        :style="{ zIndex: 10 - i }"
                                        class="inline-block size-24 overflow-hidden rounded-full shadow-[39px_36px_33px_-31px_rgb(0_0_0_/_0.25)] ring-2 ring-white hover:cursor-pointer hover:shadow-[39px_36px_33px_-31px_rgb(0_0_0_/_0.6)] md:size-40"
                                        v-for="(podcaster, i) in selectedPodcasters.slice(0, 6)"
                                        :key="podcaster.id"
                                        :href="route('clipfinder.podcasters.show', [podcaster.slug])"
                                    >
                                        <picture class="h-full w-full">
                                            <source class="h-full w-full" :srcset="podcaster.webp_picture_url" type="image/webp" height="160" width="160" />
                                            <img
                                                class="h-full w-full"
                                                :src="podcaster.picture_url"
                                                :alt="podcaster.name"
                                                height="160"
                                                width="160"
                                                loading="lazy"
                                            />
                                        </picture>
                                    </Link>

                                    <Link
                                        :href="route('clipfinder.podcasters.index')"
                                        v-if="invisiblePodcastersCount"
                                        class="z-0 inline-flex size-24 items-center justify-center rounded-full bg-primary shadow-[39px_36px_33px_-31px_rgb(0_0_0_/_0.25)] ring-2 ring-white md:size-40"
                                    >
                                        <span class="pl-14 text-base font-bold text-white md:pl-20 md:text-3xl">+{{ invisiblePodcastersCount }}</span>
                                    </Link>
                                </div>
                            </div>

                            <h1 class="text-lg font-bold text-slate-800 md:text-3xl xl:text-4xl">
                                Start listening to

                                <span
                                    class="ml-0.5 inline-block rounded-lg border-0 bg-primary px-2 py-0.5 text-lg text-white focus:ring-2 focus:ring-primary-400 focus:ring-offset-1 md:text-3xl lg:inline xl:text-4xl"
                                >
                                            podcasts
                                        </span>

                                <br />
                                where your interest begins.
                            </h1>
                        </div>

                        <div class="mx-auto mt-6 w-full max-w-2xl px-4 sm:px-6 lg:px-8">
                            <ChatFooter
                                :conversation-starters="show_conversation_starters ? conversationStarters : []"
                                @submit="scrollToBottom(scroller, { smooth: true })"
                            />
                        </div>
                    </div>

                    <div class="relative mx-auto w-full px-3 pb-4 md:max-w-2xl md:px-5 lg:px-3 xl:max-w-3xl xl:px-5 flex flex-col items-center">
                        <div v-for="(message, i) in messages" :key="message.id" class="w-full">
                            <!-- User Message with consistent max-width -->
                            <div class="sm:max-w-xl lg:w-[36rem] mx-auto">
                                <ChatMessage align="left" :avatar="user?.avatar_url" :date="message.date" :email="user?.email">
                                    <ChatBubble align="left" :primary="true">
                                        <div class="text-sm font-bold flex items-center" v-if="message.original_question != messages[i - 1]?.original_question">
                                            You
                                        </div>
                                        <div class="text-sm font-bold flex items-center" v-else>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z" />
                                            </svg>
                                            &nbsp;{{ message.from_year }}
                                            <span v-if="message.from_year != message.till_year">&nbsp;-&nbsp;{{ message.till_year }}</span>
                                        </div>
                                        <div class="-mt-1 break-all text-sm">
                                            {{ message.original_question }}
                                        </div>
                                    </ChatBubble>
                                </ChatMessage>
                            </div>

                            <!-- Podcaster Message -->
                            <PodcasterMessage
                                :message="message"
                                @topic-selected="scrollToBottom(scroller, { smooth: true })"
                                :is-last-message="i === messages.length - 1"
                            />
                        </div>
                    </div>

                    <div ref="endOfScroller"></div>
                    <FailedMessage v-if="scrollState === ScrollState.Failed">
                        We couldn't process your query due to an internal error. Please try again later.
                    </FailedMessage>
                </div>
            </div>
        </div>
    </div>

    <ChatFooter
        v-if="messages.length > 0"
        :conversation-starters="[]"
        @submit="scrollToBottom(scroller, { smooth: true })"
    />
</template>

<script setup lang="ts">
import FailedMessage from '@/Components/FailedMessage.vue'
import PodcasterMessage from '@/Components/PodcasterMessage.vue'
import { OverscrollDirection, useInfiniteScroll } from '@/Composables/useScrollEvents'
import type { OverscrollOptions } from '@/Composables/useScrollEvents'
import scrollToBottom from '@/Composables/useScrolltoBottom'
import { ScrollState, useConversationStore } from '@/Stores/conversation'
import { definePodcasterStore, usePodcasterStore } from '@/Stores/podcaster'
import { storeToRefs } from 'pinia'
import { Head, Link, router } from '@inertiajs/vue3'
import { onMounted, computed, ref, watch, type Ref } from 'vue'
import ChatHeader from '@/Components/ChatHeader.vue'
import ChatFooter from '@/Components/ChatFooter.vue'
import { useMotion } from '@vueuse/motion'

import ChatBubble from '@/Components/ChatBubble.vue'
import ChatMessage from '@/Components/ChatMessage.vue'
import { useUserStore } from '@/Stores/user'
import type { CategoryResource, ConversationStarterResource, PodcasterResource } from '@/types'

const show_conversation_starters = false

const props = defineProps<{
    scrollTo?: string
    initialPodcasters?: PodcasterResource[]
    isLoadingFollowState?: boolean
    podcaster?: PodcasterResource
}>()

const question: Ref<string | null> = ref(null)
const errors = ref<{ string: string[] }[]>([])
const failed = ref<Boolean>(false)

const submit = () => {
    submitQuery(question.value || '')
}

const submitQuery = async (query: string) => {
    try {
        const conversation = await sendMessage(query, true)
        router.visit(route('clipfinder.conversations.show', [conversation]))
    } catch (e: any) {
        failed.value = true
        errors.value = e.errors || []
    }
}

const userStore = useUserStore()
const podcasterStore = usePodcasterStore()
const conversationStore = useConversationStore()

const { user, loggedIn } = storeToRefs(userStore)
const { selectedPodcasters, conversationStarters, followedPodcasters, podcasters } = storeToRefs(podcasterStore)
const isLoadingFollowState = ref(false)

const { loadMoreClips } = conversationStore
const { messages, scrollState, shared } = storeToRefs(conversationStore)

const $podcasterEL = ref<HTMLElement>()
const scroller = ref<HTMLElement>()
const endOfScroller = ref<HTMLElement>()

const fetchInitialFollowState = async () => {
    if (loggedIn.value && props.podcaster?.id) {
        isLoadingFollowState.value = true;
        try {
            await podcasterStore.fetchFollowedPodcasters();
        } catch (error) {
            ScrollState.Failed
        } finally {
            isLoadingFollowState.value = false;
        }
    }
};

const handlePodcasterSelection = () => {
    const pathParts = window.location.pathname.split('/')
    const slugsFromUrl = pathParts.slice(2)

    if (slugsFromUrl.length > 0 && slugsFromUrl[0] !== 'start') {
        const selectedPodcasters = podcasters.value.filter(p => slugsFromUrl.includes(p.slug)).slice(0, 6)
        if (selectedPodcasters.length > 0) {
            podcasterStore.selectPodcasters(selectedPodcasters.map(p => p.id))
        }
    }
    else if (slugsFromUrl[0] === 'start' || slugsFromUrl.length === 0) {
        // Reset to initial state when URL is /conversations/start or just /conversations
        if (props.initialPodcasters && props.initialPodcasters.length > 0) {
            podcasterStore.selectPodcasters(props.initialPodcasters.map(p => p.id))
        }
    }
}

onMounted(async () => {
    if (shared && props.scrollTo) {
        const $scrollToEl = document.getElementById('clip_' + props.scrollTo)
        $scrollToEl?.scrollIntoView()
    }

    handlePodcasterSelection()
    await fetchInitialFollowState();

    useInfiniteScroll(scroller.value, endOfScroller.value, loadMoreClips, {
        direction: OverscrollDirection.bottom,
        rootMargin: '750px',
    } as OverscrollOptions)

    podcasterStore.fetchFollowedPodcasters()
})

watch(
    () => props.podcaster?.id,
    async (newId) => {
        if (newId && loggedIn.value) {
            await fetchInitialFollowState();
            await podcasterStore.fetchFollowedPodcasters();
        }
    }
);

useMotion($podcasterEL, {
    initial: { opacity: 0, y: -10 },
    enter: { opacity: 1, y: 0, transition: { delay: 0, duration: 500 } },
    leave: { opacity: 0, y: -10, transition: { delay: 0, duration: 200 } },
})

const isWaiting = computed(() => scrollState.value === ScrollState.Requesting)

const invisiblePodcastersCount = computed(() => Math.max(0, selectedPodcasters.value.length - 6))
</script>
