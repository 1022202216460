<template>
    <ContentLayout>
        <div class="relative">
            <PodcasterHeader
                :podcaster="podcaster"
                :total-podcasts="totalPodcasts"
                :selected-category="selectedCategory ?? undefined"
                :is-seemore-view="isSeemoreView"
                :invisible-podcasters-count="invisiblePodcastersCount"
                :is-loading-follow-state="isLoadingFollowState"
            />

            <div v-if="!isStartRoute" class="mt-8 mb-8">
                <div class="sticky bottom-0 bg-white">
                    <ConversationStartersList class="mt-4" :starters="podcaster?.conversation_starters ?? []" @selected="submitQuery" />
                </div>
            </div>

            <div class="h-full w-full overflow-y-auto scrollbar-hide" id="messages" ref="scroller">
                <div class="flex h-full flex-col pb-9 text-sm dark:bg-transparent">
                    <div class="mt-4 grid grid-cols-1 gap-x-6 gap-y-3 md:grid-cols-2 xl:grid-cols-3">
                        <div v-for="(podcast, index) in displayedPodcasts" :key="podcast.id">
                            <div class="relative">
                                <template v-if="index < 2">
                                    <div class="relative mx-auto w-full overflow-hidden rounded-lg shadow-lg cursor-pointer"
                                    @click="goToPodcastPage(podcast.external_id)">
                                        <div class="h-70">
                                            <img
                                                :src="`https://img.youtube.com/vi/${podcast.external_id}/maxresdefault.jpg`"
                                                :alt="podcast.title"
                                                class="w-full h-full object-cover"
                                            />
                                            <div class="absolute inset-0 flex items-center justify-center">
                                                <YoutubeIcon class="h-12 w-12 fill-current text-red-600" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="relative flex items-center overflow-hidden">
                                        <div class="flex-grow p-2" style="max-width: calc(100% - 1.5rem)">
                                            <p class="truncate text-xs font-semibold text-gray-700">
                                                {{ new Date(podcast.date)?.toLocaleDateString() }}
                                            </p>
                                            <div class="truncate text-sm font-semibold text-gray-800 sm:text-base" v-html="podcast.title"></div>
                                        </div>
                                        <div class="absolute right-2 top-1/2 -translate-y-1/2 transform">
                                            <YoutubeIcon class="h-6 w-6 fill-current text-red-600" />
                                        </div>
                                    </div>
                                </template>
                                <PodcastCard
                                    v-else
                                    :podcast="podcast"
                                    :podcaster="podcaster"
                                    :is-overlay="index === displayedPodcasts.length - 1 && hasMorePodcasts && !isSeemoreView"
                                    @see-more="navigateToSeeMore"
                                />
                            </div>
                        </div>
                    </div>


                    <div class="mt-8 w-full px-4 sm:px-6 lg:px-8 flex justify-center">
                        <TimelineComponent :podcaster="podcaster.id" :show-all-clips="true" :score-threshold="1.0" />
                    </div>

                    <div ref="endOfScroller"></div>
                    <FailedMessage v-if="scrollState === ScrollState.Failed">
                        We couldn't process your query due to an internal error. Please try again later.
                    </FailedMessage>
                </div>
            </div>

            <div class="sticky bottom-0 bg-white mx-4 leading-6 text-black md:mx-0">
                <form @submit.prevent="submit" class="relative w-full max-w-full text-black md:mx-auto lg:max-w-2xl xl:max-w-3xl">
                    <SearchInput
                        v-model="form.query"
                        @submit="submit"
                        :disabled="isWaiting"
                        :placeholder="placeholder"
                        :error="errors.hasOwnProperty('question')"
                    />
                </form>
                <div class="relative py-3 text-center text-xs leading-4 text-gray-600 md:px-16 md:py-4">
                    <span class="text-gray-600">
                        Clipfinder.com knows all the podcasts until
                        <time class="italic" datetime="{{ trainingDate }}">{{ trainingDate.toLocaleDateString() }}</time>
                    </span>
                </div>
            </div>
        </div>
    </ContentLayout>
</template>

<script setup lang="ts">
import { onMounted, computed, ref, inject, reactive, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { router } from '@inertiajs/vue3'

import ContentLayout from '@/Layouts/ContentLayout.vue'

import PodcastCard from '@/Components/PodcastCard.vue'
import ClipComponent from '@/Components/ClipComponent.vue'
import FailedMessage from '@/Components/FailedMessage.vue'
import ConversationStartersList from '@/Pages/Podcaster/ConversationStartersList.vue'
import { ScrollState, useConversationStore } from '@/Stores/conversation'
import { usePodcasterStore } from '@/Stores/podcaster'
import { useUserStore, defineUserStore } from '@/Stores/user'
import { definePodcastStore } from '@/Stores/podcast'
import { defineCategoryStore, useCategoryStore } from '@/Stores/category'
import type { CategoryResource, ClipResource, PodcasterResource, ConversationStarterResource } from '@/types'
import PodcasterHeader from '@/Components/PodcasterHeader.vue'
import 'vue-lite-youtube-embed/style.css'
import YoutubeIcon from '@/Icons/YoutubeIcon.vue'
import SearchInput from '@/Components/SearchInput.vue'
import TimelineComponent from '@/Components/TimelineComponent.vue'

const route = inject<any>('route')

defineCategoryStore()
defineUserStore()

const props = defineProps<{
    podcaster?: PodcasterResource
    conversationStarters?: ConversationStarterResource[]
    category?: CategoryResource
    clips?: ClipResource
    isSeemoreView?: boolean
    starters?: ConversationStarterResource[]
    scrollTo?: string
}>()

const userStore = useUserStore()
const podcasterStore = usePodcasterStore()

if (props.podcaster) {
    podcasterStore.selectPodcasters([props.podcaster.id])
}
const conversationStore = useConversationStore()
const categoryStore = useCategoryStore()
const { selectedPodcasters, trainingDate, conversationStarters } = storeToRefs(podcasterStore)
const { scrollState, shared } = storeToRefs(conversationStore)
const { categories, selectedCategory } = storeToRefs(categoryStore)
const { loggedIn } = storeToRefs(userStore)

const store = definePodcastStore(props.podcaster)
const { podcasts, failed, totalPodcasts } = storeToRefs(store)

const scroller = ref<HTMLElement>()
const endOfScroller = ref<HTMLElement>()
const errors = ref<{ [key: string]: string[] }>({})
const isLoadingFollowState = ref<boolean>(false)

const maxDisplayPodcasts = props.isSeemoreView ? Infinity : 3
const displayedPodcasts = computed(() => podcasts.value.slice(0, maxDisplayPodcasts))
const hasMorePodcasts = computed(() => podcasts.value.length > maxDisplayPodcasts)
const invisiblePodcastersCount = computed(() => Math.max(0, selectedPodcasters.value.length - 6))

const isStartRoute = computed(() => {
    return route().current() === 'clipfinder.conversations.start'
})

const form = reactive({
    query: '',
})

const placeholder = computed(() => {
    return podcasterStore.selectedPodcasters.length === 1
        ? `Ask a question to ${podcasterStore.selectedPodcasters[0].name}...`
        : `Ask a question...`
})

const emit = defineEmits(['submit'])

const isWaiting = computed(() => scrollState.value === ScrollState.Requesting)

const navigateToSeeMore = () => {
    try {
        if (!props.podcaster?.slug) {
            return;
        }

        router.get(
            route('clipfinder.podcasters.podcasts.index', {
                podcaster: props.podcaster.slug
            }),
            {},
            {
                preserveState: false,
                preserveScroll: false,
                replace: false
            }
        );
    }
    catch (e: any) {
        ScrollState.Failed;
    }
}

const goToPodcastPage = (videoId: string) => {
    try {
        if (!props.podcaster?.slug) {
            return;
        }

        const url = route('clipfinder.podcaster.podcasts.video', {
            podcaster: props.podcaster.slug,
            videoId: videoId
        });

        if (!url) {
            return;
        }

        router.visit(url);
    }
    catch (e: any) {
       ScrollState.Failed;
    }
}

const submit = async () => {

    if (isWaiting.value || !props.podcaster) {
        return
    }

    try {
        const question = form.query
        form.query = ''

        if (props.podcaster.id) {
            podcasterStore.selectPodcasters([props.podcaster.id])

            if (!podcasterStore.selectedPodcastersIds.includes(props.podcaster.id)) {
                throw new Error('Failed to select podcaster')
            }

            const conversation = await conversationStore.sendMessage(question, true)

            if (conversation) {
                router.visit(route('clipfinder.conversations.show', [conversation]))
            }

            emit('submit', question)
        }
        else {
            throw new Error('Invalid podcaster ID')
        }
    } catch (e: any) {
        errors.value = e.errors || []
    }
}

const submitQuery = async (question: string) => {
    if (!props.podcaster) return

    try {
        store.setFailed(false)
        // Ensure only this podcaster is selected
        podcasterStore.selectPodcasters([props.podcaster.id])
        const conversation = await conversationStore.sendMessage(question, true)

        if (conversation) {
            router.visit(route('clipfinder.conversations.show', [conversation]))
            scroller.value?.scrollIntoView({ behavior: 'smooth' })
        }
    }
    catch (e: any) {
        store.setFailed(true)
    }
}

async function loadFollowedPodcasters() {
    if (loggedIn.value && props.podcaster?.id) {
        isLoadingFollowState.value = true;
        await podcasterStore.fetchFollowedPodcasters();
        isLoadingFollowState.value = false;
    }
}

watch(
  () => props.podcaster?.id,
  async (newId) => {
    if (newId && loggedIn.value) {
      isLoadingFollowState.value = true;
      await podcasterStore.fetchFollowedPodcasters();

      // Correct state sync after refresh
      const isFollowed = podcasterStore.followedPodcasters.some(p => p.id === newId);
      podcasterStore.updatePodcasterFollowStatus(newId, isFollowed);
      isLoadingFollowState.value = false;
    }
  }
)

onMounted(async () => {  // Make the callback async
    if (!props.podcaster) return;

    if (loggedIn.value && props.podcaster?.id) {
        isLoadingFollowState.value = true;
        await loadFollowedPodcasters();
        isLoadingFollowState.value = false;
    }

    if (shared.value && props.scrollTo) {
        const $scrollToEl = document.getElementById('clip_' + props.scrollTo)
        $scrollToEl?.scrollIntoView()
    }
})
</script>
