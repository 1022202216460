<template>
    <figure class="group relative rounded-xl border bg-white p-5 text-slate-800 shadow-md hover:cursor-pointer hover:shadow-xl">
        <div
            class="absolute left-0 top-0 h-12 w-12 -translate-x-4 -translate-y-6 transform text-primary-400 group-hover:text-primary-600 sm:h-16 sm:w-16"
        >
            <svg
                v-if="(clip && clip.quote) || quote"
                ref="quoteRef"
                class="h-full w-full"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
            >
                <path
                    d="M7.39762 10.3C7.39762 11.0733 7.14888 11.7 6.6514 12.18C6.15392 12.6333 5.52552 12.86 4.76621 12.86C3.84979 12.86 3.09047 12.5533 2.48825 11.94C1.91222 11.3266 1.62421 10.4467 1.62421 9.29999C1.62421 8.07332 1.96459 6.87332 2.64535 5.69999C3.35231 4.49999 4.33418 3.55332 5.59098 2.85999L6.4943 4.25999C5.81354 4.73999 5.26369 5.27332 4.84476 5.85999C4.45201 6.44666 4.19017 7.12666 4.05926 7.89999C4.29491 7.79332 4.56983 7.73999 4.88403 7.73999C5.61716 7.73999 6.21938 7.97999 6.69067 8.45999C7.16197 8.93999 7.39762 9.55333 7.39762 10.3ZM14.6242 10.3C14.6242 11.0733 14.3755 11.7 13.878 12.18C13.3805 12.6333 12.7521 12.86 11.9928 12.86C11.0764 12.86 10.3171 12.5533 9.71484 11.94C9.13881 11.3266 8.85079 10.4467 8.85079 9.29999C8.85079 8.07332 9.19117 6.87332 9.87194 5.69999C10.5789 4.49999 11.5608 3.55332 12.8176 2.85999L13.7209 4.25999C13.0401 4.73999 12.4903 5.27332 12.0713 5.85999C11.6786 6.44666 11.4168 7.12666 11.2858 7.89999C11.5215 7.79332 11.7964 7.73999 12.1106 7.73999C12.8437 7.73999 13.446 7.97999 13.9173 8.45999C14.3886 8.93999 14.6242 9.55333 14.6242 10.3Z"
                    fill="currentColor"
                />
            </svg>
            <ExclamationTriangleIcon v-else-if="clip && clip.failed" class="mt-2 h-12 w-12" />
            <ArrowPathIcon v-else class="mt-2 h-12 w-12 animate-spin" />
        </div>

        <div class="z-10 pt-3">
            <blockquote :class="{ 'max-h-36': hideFullSummary, '': !hideFullSummary }" class="text-lg sm:text-xl overflow-hidden relative">
                <span v-if="(clip && clip.quote) || quote">
                    <em v-html="prettyQuote(clip ? clip.quote : quote)"></em>
                    <span class="absolute right-0 bottom-0 text-left w-full block bg-gradient-to-l from-white to-[rgba(255,255,255,0.2)] font-bold bg-white">
                        <u v-if="hideFullSummary" class="text-primary bg-white shadow-[0_25px_50px_20px_white]">Read more</u>
                    </span>
                </span>
                <em v-else-if="podcast && !quote && podcast.data?.has_summary === false" 
                    v-html="'No summary generated for this yet clip.'" 
                    class="text-lg">
                </em>
                <Placeholder v-else :length="50" />
            </blockquote>
        </div>

        <div class="mt-5 flex items-end justify-between">
            <div>
                <p class="text-xs font-semibold text-slate-700">
                    {{ new Date(clip ? clip.podcast.date : date)?.toLocaleDateString() }}
                </p>
                <div class="text-sm font-semibold text-slate-800 sm:text-base" v-html="clip ? clip.podcast.title : title"></div>

                <span class="mt-1 text-xs italic text-slate-700">
                    <span v-if="clip && clip.quote && clip.timestamp > 0">Quote recorded at {{ timestampToString(clip.timestamp) }} </span>
                    <span v-else-if="clip && clip.timestamp > 0"> <Placeholder :length="12" /> </span>
                </span>
            </div>

            <div
                class="w-5 sm:w-8"
                :class="{
                    'text-red-500 group-hover:text-red-600': (clip && clip.timestamp),
                    'text-slate-400': !(clip && clip.timestamp),
                }"
            >
                <YoutubeIcon class="h-5 w-5 fill-current sm:h-8 sm:w-8" />
            </div>
        </div>
    </figure>
</template>
<script setup lang="ts">
import Placeholder from '@/Components/Placeholder.vue'
import YoutubeIcon from '@/Icons/YoutubeIcon.vue'
import type { ClipResource, PodcastResource } from '@/types'
import { prettyQuote, timestampToString } from '@/Utils/String'
import { ArrowPathIcon, ExclamationTriangleIcon } from '@heroicons/vue/24/solid'
import { useMotion } from '@vueuse/motion'
import { ref } from 'vue'

const quoteRef = ref<HTMLElement>()

const { podcast, clip, hideFullSummary } = defineProps<{
    clip?: ClipResource,
    podcast?: PodcastResource,
    quote?: string,
    date?: string,
    title?: string,
    hideFullSummary?: boolean,
}>()

const getTrimmedSummary = (message) => {
    const max_chars = 215

    return hideFullSummary ? (message.length > max_chars
            ? (message.slice(0, max_chars).split(/\s/).slice(0, -1).join(' ').replace(/([a-zA-Z0-9])$/, '$1...').replace(/,$/, '...').replace(/([.!?])$/, '$1')).replace(/\s$/, '')
            : message) : message
}

useMotion(quoteRef, {
    initial: { opacity: 0, y: -20 },
    enter: { opacity: 1, y: 0, transition: { delay: 300, duration: 200 } },
})
</script>
