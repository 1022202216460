<template>
    <div ref="clipContainer" class="flex flex-col items-center">
        <FailedMessage v-if="selectedClips.length === 0 && message.failed" with-retries @retrying="retry" :retrying="retrying">
            Something went wrong. You can retry by clicking the "Retry" button. If the error keeps occurring, please contact support.
        </FailedMessage>

        <!-- TODO: make sure this isn't shown before the agent is fully finished -->
        <div v-if="selectedClips.length === 0 && message.suggestions.length > 0">
            <MessageBubble
                align="center"
                :date="message.created_at ?? new Date().toISOString()"
                :bubbleClass="'rounded-lg border bg-white p-3 font-normal text-slate-600 shadow'"
                :avatar="'error'"
            >
                <div class="flex items-center">
                    <div class="mr-3 flex h-7 w-7 shrink-0 items-center justify-center rounded-full border border-orange-300 bg-orange-100 p-1">
                        <ExclamationTriangleIcon class="h-4 text-orange-600" />
                    </div>
                    <div>
                        <div class="font-semibold text-gray-700">No clips</div>
                        <div class="text-sm text-gray-500">
                            No relevant clips could be found for this query. Do you want to try one of the following topics?
                        </div>
                    </div>
                </div>
            </MessageBubble>
        </div>

        <div class="sm:max-w-xl lg:w-[36rem]">
            <MediaMessageComponent
                v-for="clip in message.clips || []"
                :key="'clip-' + clip.id"
                type="clip"
                :clip="clip"
                messageAvatar="anonymous"
                :messageDate="clip.podcast?.date"
                :hideAvatarName="false"
            />
            <ClipLoader
                key="clip-loader"
                v-if="
                    !shared &&
                    !message.failed &&
                    ((selectedClips.length === 0 && message.suggestions.length === 0) || (isLastMessage && scrollState === ScrollState.Waiting))
                "
                :title="conversationStore.loadingText"
            />
        </div>

        <div v-if="!shared && message.suggestions.length > 0" class="mb-4 max-w-[36rem]">
            <div :class="{ 'mt-8': !message.failed }" v-if="message.clips.filter((clip) => !clip.rejected).length > 0" class="flex justify-center mb-14">
                <span @click="searchEarlierClips(message)"
                      v-if="isLastMessage"
                      class="relative inline-flex items-center m-0 cursor-pointer select-none rounded-full border border-solid border-stone-300 bg-transparent px-3 py-1 text-center font-sans font-medium normal-case leading-4 text-zinc-600 outline-offset-2 outline-transparent duration-300 ease-in-out space-x-2">
                    <span>Continue in {{ message.from_year ? message.from_year - 1 : conversationStore.fromYear - 1 }}: {{ message.original_question }}</span>

                    <button
                        :class="{
                            'bg-gray-200 text-gray-500': disabled,
                            'bg-primary-50 text-primary-600': !disabled,
                        }"
                        class="inline-flex size-7 shrink-0 items-center justify-center rounded-full border-0 p-1 hover:bg-primary-600 hover:text-white focus:bg-primary-600 focus:text-white active:bg-primary-500/25 md:size-8 md:h-9 md:w-9"
                    >
                        <PaperAirplaneIcon v-if="!disabled" class="size-4 md:size-5" />
                        <ArrowPathIcon v-else class="size-4 animate-spin md:size-5" />
                    </button>
                </span>
            </div>

            <ClipSuggestions :message="message" @selected="selectSuggestion" />
        </div>

        <div
            class="sticky inset-x-0 bottom-4 z-50 flex items-center justify-center"
            v-if="
                !shared &&
                isLastMessage &&
                selectedClips.length > 0 &&
                message.suggestions.length === 0 &&
                scrollState !== ScrollState.Ended &&
                scrollState !== ScrollState.Failed
            "
        >
            <div class="borders flex items-center rounded-full bg-white/60 px-3 py-0.5 shadow backdrop-blur">
                <VerticalScrollerIcon class="mr-1 h-7 fill-current text-primary-600" />
                <span class="text-sm font-semibold leading-5 text-black/80">Scroll to continue...</span>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import MessageBubble from '@/Components/MessageBubble.vue'
import MediaMessageComponent from '@/Components/MediaMessageComponent.vue'
import ClipLoader from '@/Components/ClipLoader.vue'
import FailedMessage from '@/Components/FailedMessage.vue'
import VerticalScrollerIcon from '@/Icons/VerticalScrollerIcon.vue'
import { ScrollState, useConversationStore } from '@/Stores/conversation'
import type { ClipFinderMessageResource, ClipSuggestionResource } from '@/types'
import { ArrowPathIcon, ExclamationTriangleIcon, PaperAirplaneIcon } from '@heroicons/vue/24/outline'
import { storeToRefs } from 'pinia'
import { computed, type ComputedRef, onMounted, onUnmounted, ref } from 'vue'
import ClipSuggestions from '@/Components/ClipSuggestions.vue'

const emits = defineEmits(['topic-selected'])

const props = defineProps<{
    message: ClipFinderMessageResource
    isLastMessage: Boolean
}>()

onUnmounted(() => {
    conversationStore.conversationId = null
})

onMounted(() => {
    if (window.location.pathname.startsWith('/conversations/')) {
        conversationStore.conversationId = window.location.pathname.split('/').pop()
    }
})

const conversationStore = useConversationStore()
const { continueBySuggestedTopic, retryMessage, searchEarlierClips } = conversationStore
const { scrollState, lastMessage, shared } = storeToRefs(conversationStore)

const retrying = ref<Boolean>(false)
const clipContainer = ref<HTMLElement>()

const selectSearchEarlierClips = async () => {
    if (shared.value) {
        return
    }

    await searchEarlierClips(lastMessage.value)

    emits('topic-selected', suggestion.topic)
}

const selectSuggestion = async (suggestion: ClipSuggestionResource) => {
    if (shared.value) {
        return
    }

    await continueBySuggestedTopic(suggestion)

    emits('topic-selected', suggestion.topic)
}

const retry = async () => {
    if (shared.value) {
        return
    }

    retrying.value = true
    await retryMessage(props.message.id, () => (retrying.value = false))
}

const selectedClips = computed(() => props.message.clips.filter((clip) => clip.selected))
</script>
