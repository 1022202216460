<template>
    <PageHeader>
        <template #left>
            <PodcasterSelectbox />
        </template>
        <template #right>
            <button
                @click="configurationSlideOver = true"
                id="configuration"
                aria-label="configuration"
                class="ring-inset-1 relative flex items-center justify-center whitespace-nowrap rounded-lg px-2 py-1 ring-1 ring-gray-300 focus:ring focus:ring-primary"
            >
                <div class="flex w-full items-center justify-center gap-2">
                    <AdjustmentsVerticalIcon class="size-4 md:size-5" />
                </div>
            </button>

            <button
                v-if="!shared"
                @click="share"
                id="share"
                aria-label="share"
                class="ring-inset-1 relative flex items-center justify-center whitespace-nowrap rounded-lg px-2 py-1 ring-1 ring-gray-300 focus:ring focus:ring-primary"
            >
                <div class="flex w-full items-center justify-center gap-2">
                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="size-4 md:size-5">
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M11.2929 2.29289C11.6834 1.90237 12.3166 1.90237 12.7071 2.29289L16.7071 6.29289C17.0976 6.68342 17.0976 7.31658 16.7071 7.70711C16.3166 8.09763 15.6834 8.09763 15.2929 7.70711L13 5.41421V14C13 14.5523 12.5523 15 12 15C11.4477 15 11 14.5523 11 14V5.41421L8.70711 7.70711C8.31658 8.09763 7.68342 8.09763 7.29289 7.70711C6.90237 7.31658 6.90237 6.68342 7.29289 6.29289L11.2929 2.29289ZM4 13C4.55228 13 5 13.4477 5 14V18C5 18.5523 5.44772 19 6 19H18C18.5523 19 19 18.5523 19 18V14C19 13.4477 19.4477 13 20 13C20.5523 13 21 13.4477 21 14V18C21 19.6569 19.6569 21 18 21H6C4.34315 21 3 19.6569 3 18V14C3 13.4477 3.44772 13 4 13Z"
                            fill="currentColor"
                        ></path>
                    </svg>
                </div>
            </button>
        </template>
    </PageHeader>
</template>

<script setup lang="ts">
import PodcasterSelectbox from '@/Components/PodcasterSelectbox.vue'
import { useConversationStore } from '@/Stores/conversation'
import { storeToRefs } from 'pinia'
import { AdjustmentsVerticalIcon } from '@heroicons/vue/24/outline'

import { copyToClipboard } from '@/Utils/Clipboard'
import { toast } from 'vue3-toastify'
import { inject } from 'vue'
import PageHeader from '@/Layouts/Partials/PageHeader.vue'

const conversationStore = useConversationStore()
const { shareConversation } = conversationStore
const { shared, configurationSlideOver } = storeToRefs(conversationStore)

const route = inject('route')

const share = async () => {
    if (shared.value) {
        return
    }

    if (!conversationStore.conversation) {
        await copyToClipboard(window.location.href)
    } else {
        const shareId = await shareConversation()

        await copyToClipboard(route('clipfinder.shared.show', shareId))
    }

    toast.success('Copied shareable link to clipboard', {
        autoClose: 2000,
    })
}
</script>
