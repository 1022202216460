<template>
    <ContentLayout>
        <div class="mb-5 flex justify-center">
            <picture class="inline-block size-20 rounded-full bg-gray-400 ring-2 ring-gray-100 md:size-32">
                <source class="size-20 rounded-full md:size-32" :srcset="podcaster.webp_picture_url" type="image/webp" height="160" width="160" />
                <img
                    class="size-20 rounded-full md:size-32"
                    :src="podcaster.picture_url"
                    :alt="podcaster.name"
                    height="160"
                    width="160"
                    loading="lazy"
                />
            </picture>
        </div>
        <PageTitle>
            {{ clipCount }} Brilliant <br />
            <PageTitleHighlight>{{ podcaster.name }}</PageTitleHighlight>
            <br />
            <span v-if="selectedCategory"> {{ selectedCategory.name }} </span>
            clips
        </PageTitle>

        <div class="mx-auto mt-6 max-w-3xl py-4">
            <form @submit.prevent="submit" class="relative">
                <SearchInput :rounded-full="true" v-model="question" @submit="submit" :disabled="isWaiting" placeholder="Ask a question..." />
            </form>
        </div>

        <ConversationStartersList :starters="podcaster.conversation_starters" @selected="submitQuery($event)" />

        <div class="sm:max-w-xl lg:w-[36rem]" ref="scroller">
            <div class="relative mx-auto w-full px-3 pb-4 md:max-w-2xl md:px-5 lg:px-1 xl:max-w-3xl xl:px-5">
                <div v-for="clip in clips" :key="clip.message.id">
                    <ChatMessage align="left" avatar="anonymous" :date="clip.message.date">
                        <ChatBubble align="left" :primary="true">
                            <div class="text-sm font-bold">You</div>
                            <div class="-mt-1">
                                {{ clip.message.original_question }}
                            </div>
                        </ChatBubble>
                    </ChatMessage>

                    <PodcastClip :clip="clip" :key="'clip-' + clip.id" :question="clip.message.original_question" />
                </div>
            </div>

            <ClipLoader key="clip-loader" v-if="isWaiting" />
            <div ref="endOfScroller"></div>
        </div>
    </ContentLayout>
</template>

<script setup lang="ts">
import type { CategoryResource, PodcastResource, PodcasterResource } from '@/types'
import 'vue-lite-youtube-embed/style.css'
import { defineClipStore } from '@/Stores/clip'
import { storeToRefs } from 'pinia'
import { onMounted, ref, type Ref } from 'vue'
import { useCategoryStore } from '@/Stores/category'
import ContentLayout from '@/Layouts/ContentLayout.vue'
import PageTitle from '@/Pages/Podcaster/PageTitle.vue'
import PageTitleHighlight from '@/Pages/Podcaster/PageTitleHighlight.vue'
import { OverscrollDirection, type OverscrollOptions, useInfiniteScroll } from '@/Composables/useScrollEvents'
import SearchInput from '@/Components/SearchInput.vue'
import ConversationStartersList from '@/Pages/Podcaster/ConversationStartersList.vue'
import { useConversationStore } from '@/Stores/conversation'
import { router } from '@inertiajs/vue3'
import { inject } from 'vue'
import PodcastClip from '@/Components/PodcastClip.vue'
import ChatMessage from '@/Components/ChatMessage.vue'
import ChatBubble from '@/Components/ChatBubble.vue'
import ClipLoader from '@/Components/ClipLoader.vue'
import { definePodcasterStore } from '@/Stores/podcaster'
import { ScrollState } from '@/Enum/ScrollState'

const route = inject('route')
const { podcaster, podcast, selectedCategory } = defineProps<{
    podcaster: PodcasterResource
    podcast?: PodcastResource
    selectedCategory?: CategoryResource
}>()

definePodcasterStore(podcaster ? [podcaster.id] : undefined)

const question: Ref<string | null> = ref(null)
const conversationStore = useConversationStore()
const { sendMessage } = conversationStore
const categoryStore = useCategoryStore()
const { categories } = storeToRefs(categoryStore)
const clipStore = defineClipStore(podcast, podcaster)
const { clipCount, clips, isWaiting } = storeToRefs(clipStore)
const scrollState = ref<ScrollState>(ScrollState.Default)

const submit = () => {
    submitQuery(question.value || '')
}

const submitQuery = async (query: string) => {
    try {
        const conversation = await sendMessage(query, true)
        router.visit(route('clipfinder.conversations.show', [conversation]))
    } catch (e: any) {
        scrollState.value = ScrollState.Failed
    }
}

const loadMoreClips = clipStore.loadMoreClips
const scroller = ref<HTMLElement>()
const endOfScroller = ref<HTMLElement>()

onMounted(() => {
    categoryStore.fetchPodcasterClipCategories(podcaster.id)
    if (podcast) {
        clipStore.fetchEntityClips('podcast', podcast.id)
    } else {
        clipStore.fetchEntityClips('podcaster', podcaster.id)
    }
    useInfiniteScroll(scroller.value, endOfScroller.value, loadMoreClips, {
        direction: OverscrollDirection.bottom,
        rootMargin: '100px',
    } as OverscrollOptions)
})
</script>
