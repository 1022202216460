<template>
    <div class="mb-0 mt-3 flex items-start text-slate-500 md:mt-5" :class="{ 'justify-end': align === 'right' }">
        <div v-if="avatar && avatar != 'error'" class="relative inline-flex flex-shrink-0 text-slate-500" :class="{ 'order-1': align === 'right', 'size-7': !useUserAvatar, 'size-10': useUserAvatar }">
            <Tooltip :message="email" position="bottom" v-if="useUserAvatar">
                <img
                    class="block h-full w-full max-w-full rounded-full object-cover object-center align-middle ring-1 ring-gray-300"
                    :src="avatar"
                    :alt="email ?? 'Avatar'"
                />
            </Tooltip>
            <Tooltip message="Topic" position="bottom" v-else-if="avatar == 'topic'">
                <span class="relative z-5 inline-block size-7 mt-1.5 flex items-center justify-center font-bold">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 256 256" enable-background="new 0 0 256 256" xml:space="preserve"><g xmlns="http://www.w3.org/2000/svg"><path fill="#000000" d="M198.8,33.6c13,0,23.6,10.6,23.6,23.6v94.4c0,13-10.6,23.6-23.6,23.6h-23.6c-7.1,0-14.2,3.5-18.9,9.4L128,222.4l-28.3-37.8c-4.7-5.9-11.8-9.4-18.9-9.4H57.2c-13,0-23.6-10.6-23.6-23.6V57.2c0-13,10.6-23.6,23.6-23.6H198.8 M198.8,10H57.2C31.2,10,10,31.2,10,57.2v94.4c0,26,21.2,47.2,47.2,47.2h23.6l30.7,40.1c4.7,4.7,10.6,7.1,16.5,7.1c5.9,0,11.8-2.4,16.5-7.1l30.7-40.1h23.6c26,0,47.2-21.2,47.2-47.2V57.2C246,31.2,224.8,10,198.8,10L198.8,10z"/><path fill="#000000" d="M165.8,92.6H90.2c-4.7,0-9.4-4.7-9.4-9.4v-4.7c0-4.7,4.7-9.4,9.4-9.4h75.5c4.7,0,9.4,4.7,9.4,9.4v4.7C175.2,87.9,170.5,92.6,165.8,92.6z"/><path fill="#000000" d="M165.8,139.8H90.2c-4.7,0-9.4-4.7-9.4-9.4v-4.7c0-4.7,4.7-9.4,9.4-9.4h75.5c4.7,0,9.4,4.7,9.4,9.4v4.7C175.2,135.1,170.5,139.8,165.8,139.8z"/></g></svg>
                </span>
            </Tooltip>
            <Tooltip message="New Podcast" position="bottom" v-else-if="avatar == 'podcast'">
                <span class="relative z-5 inline-block size-7 mt-1.5 flex items-center justify-center font-bold">
                    <svg viewBox="136.3774 182.2449 236 236.004" width="236px" height="236.004px">
                        <path fill="#000000" d="M 440.667 -166.57 C 453.667 -166.57 464.267 -155.97 464.267 -142.97 L 464.267 -48.566 C 464.267 -35.566 453.667 -24.966 440.667 -24.966 L 417.067 -24.966 C 409.967 -24.966 402.867 -21.466 398.167 -15.566 L 369.867 22.234 L 341.567 -15.566 C 336.867 -21.466 329.767 -24.966 322.667 -24.966 L 299.067 -24.966 C 286.067 -24.966 275.467 -35.566 275.467 -48.566 L 275.467 -142.97 C 275.467 -155.97 286.067 -166.57 299.067 -166.57 L 440.667 -166.57 M 440.667 -190.17 L 299.067 -190.17 C 273.067 -190.17 251.867 -168.97 251.867 -142.97 L 251.867 -48.566 C 251.867 -22.566 273.067 -1.366 299.067 -1.366 L 322.667 -1.366 L 353.367 38.734 C 358.067 43.434 363.967 45.834 369.867 45.834 C 375.767 45.834 381.667 43.434 386.367 38.734 L 417.067 -1.366 L 440.667 -1.366 C 466.667 -1.366 487.867 -22.566 487.867 -48.566 L 487.867 -142.97 C 487.867 -168.97 466.667 -190.17 440.667 -190.17 Z" transform="matrix(1, 0, 0, 1, -115.4896469116211, 372.4149475097656)"/>
                        <path d="M 367.446 -143.057 L 378.654 -109.023 L 412.782 -108.289 L 385.58 -86.52 L 395.465 -52.033 L 367.446 -72.613 L 339.427 -52.033 L 349.312 -86.52 L 322.11 -108.289 L 356.238 -109.023 Z" bx:shape="star 367.446 -92.74 47.669 50.317 0.4 5 1@d195ba55" style="stroke: rgb(0, 0, 0);" transform="matrix(1, 0, 0, 1, -115.4896469116211, 372.4149475097656)"/>
                    </svg>
                </span>
            </Tooltip>
            <Tooltip message="Anonymous user" position="bottom" v-else-if="avatar == 'anonymous'">
                <span class="relative z-5 inline-block size-7 mt-1.5 flex items-center justify-center font-bold">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#D1D5DB" d="M64 0C28.7 0 0 28.7 0 64L0 352c0 35.3 28.7 64 64 64l96 0 0 80c0 6.1 3.4 11.6 8.8 14.3s11.9 2.1 16.8-1.5L309.3 416 448 416c35.3 0 64-28.7 64-64l0-288c0-35.3-28.7-64-64-64L64 0z"/><path fill="#9CA3AF" d="M160 368c26.5 0 48 21.5 48 48l0 16 72.5-54.4c8.3-6.2 18.4-9.6 28.8-9.6L448 368c8.8 0 16-7.2 16-16l0-288c0-8.8-7.2-16-16-16L64 48c-8.8 0-16 7.2-16 16l0 288c0 8.8 7.2 16 16 16l96 0zm48 124l-.2 .2-5.1 3.8-17.1 12.8c-4.8 3.6-11.3 4.2-16.8 1.5s-8.8-8.2-8.8-14.3l0-21.3 0-6.4 0-.3 0-4 0-48-48 0-48 0c-35.3 0-64-28.7-64-64L0 64C0 28.7 28.7 0 64 0L448 0c35.3 0 64 28.7 64 64l0 288c0 35.3-28.7 64-64 64l-138.7 0L208 492z"/><g transform="translate(306, 216) scale(0.5) translate(-256, -256)"><path fill="#6B7280" d="M80 160c0-35.3 28.7-64 64-64l32 0c35.3 0 64 28.7 64 64l0 3.6c0 21.8-11.1 42.1-29.4 53.8l-42.2 27.1c-25.2 16.2-40.4 44.1-40.4 74l0 1.4c0 17.7 14.3 32 32 32s32-14.3 32-32l0-1.4c0-8.2 4.2-15.8 11-20.2l42.2-27.1c36.6-23.6 58.8-64.1 58.8-107.7l0-3.6c0-70.7-57.3-128-128-128l-32 0C73.3 32 16 89.3 16 160c0 17.7 14.3 32 32 32s32-14.3 32-32zm80 320a40 40 0 1 0 0-80 40 40 0 1 0 0 80z"/></g></svg>
                </span>
            </Tooltip>
        </div>

        <div
            class="ml-2 mr-0 flex flex-col items-start sm:mr-0"
            :class="{
                'items-end': align === 'right',
                'items-start': align === 'left',
            }"
        >
            <div
                :class="{
                    'mr-1 max-w-xl sm:mr-4': align === 'right',
                    'sm:mr-4': align === 'left',
                }"
            >
                <div>
                    <div v-if="thinking">
                        <div class="mt-3 animate-pulse font-normal text-slate-600">Thinking...</div>
                    </div>

                    <slot v-else />

                    <div class="ml-0.5 mt-3 text-xs md:flex md:items-start md:justify-between">
                        <div class="mb-0 mr-0 text-right text-xs leading-4 text-slate-400" v-if="!thinking && align === 'right'">
                            {{ time }}
                        </div>
                        <slot name="metadata" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import moment from 'moment/moment'
import type { ComputedRef } from 'vue'
import { computed } from 'vue'
import Tooltip from '@/Components/Tooltip.vue'

const useUserAvatar = computed(() => avatar != 'topic' && avatar != 'anonymous' && avatar != 'podcast')

const { date, email, avatar } = defineProps<{
    align: string
    avatar?: string
    date: string
    email?: string
    thinking?: Boolean
    failed?: Boolean
}>()

const time: ComputedRef<string | null> = computed((): string | null => {
    if (!date) {
        return null
    }

    return moment(date).format('h:mm')
})
</script>
