import useEventsBus from '@/Composables/useEventBus'
import type { ComponentPublicInstance, Ref } from 'vue'
import { nextTick, onUnmounted, ref, watch } from 'vue'
import type LiteYouTubeEmbed from 'vue-lite-youtube-embed'
import { usePlayerStore } from '@/Stores/player'
import { storeToRefs } from 'pinia'
import { useYoutubeScripts } from '@/Composables/useYoutubeScripts'

const { on, emit } = useEventsBus()

// Enum for player states
enum PlayerState {
    UNINITIALIZED = 'uninitialized',
    LOADING = 'loading',
    READY = 'ready',
    PLAYING = 'playing',
    PAUSED = 'paused'
}

// Central registry for all player instances
const playerRegistry = new Map<string, {
    player: YT.Player | null;
    state: PlayerState;
    pendingPlay: {
        timestamp: number;
        interval: any;
    } | null;
}>();

// Track the currently playing video ID and add a lastPlayTimestamp to prevent race conditions
let currentlyPlayingId: string | null = null;
let lastPlayTimestamp = 0;

// Function to notify all instances that a video is playing
function notifyVideoPlaying(playingId: string) {
    // Update the global currently playing ID
    currentlyPlayingId = playingId;

    // Emit an event to notify all instances
    emit('playing', {
        id: playingId
    });
}

export default function useYoutube(id: string) {
    const playerStore = usePlayerStore()
    const { playbackRate } = storeToRefs(playerStore)

    const iframe: Ref<ComponentPublicInstance<typeof LiteYouTubeEmbed> | null> = ref(null)
    const playerState = ref<PlayerState>(PlayerState.UNINITIALIZED)

    // Register this player instance in the registry
    if (!playerRegistry.has(id)) {
        playerRegistry.set(id, {
            player: null,
            state: PlayerState.UNINITIALIZED,
            pendingPlay: null
        });
    }

    // Clean up on component unmount
    onUnmounted(() => {
        const playerData = playerRegistry.get(id);
        if (playerData?.player) {
            playerData.player.destroy();
        }
        playerRegistry.delete(id);

        if (currentlyPlayingId === id) {
            currentlyPlayingId = null;
        }
    });

    // Listen for global playing events
    on('playing', ({ playing_id }): void => {
        // Pause this player only if:
        // 1. The playing event is for a different player
        // 2. This player is not already the current playing one
        // 3. This player is currently in the PLAYING state
        if (id !== playing_id && id !== currentlyPlayingId && playerState.value === PlayerState.PLAYING) {
            pause();
        }
    });

    // Initialize the player when iframe is added
    async function onIframeAdded(): Promise<void> {
        try {
            playerState.value = PlayerState.LOADING;
            const playerData = playerRegistry.get(id);

            if (!playerData) {
                throw new Error(`Player data not found for ID: ${id}`);
            }

            // Update registry state
            playerData.state = PlayerState.LOADING;
            playerRegistry.set(id, playerData);

            // Ensure iframe exists
            if (!iframe.value) {
                console.warn("Iframe reference not available");
                return;
            }

            // Load YouTube API if needed
            await useYoutubeScripts();

            // Create new player instance
            return nextTick(() => {
                if (!iframe.value) return;

                // Store the player instance
                playerData.player = new YT.Player(iframe.value.getPlayerInstance(), {
                    events: {
                        onReady: (event) => {
                            // Update player state
                            playerState.value = PlayerState.READY;

                            // Update registry state
                            const currentPlayerData = playerRegistry.get(id);
                            if (currentPlayerData) {
                                currentPlayerData.state = PlayerState.READY;
                                playerRegistry.set(id, currentPlayerData);
                            }

                            // Set initial playback rate
                            const rate = Number(playbackRate.value);
                            event.target.setPlaybackRate(rate);
                        },
                        onStateChange: (event: YT.OnStateChangeEvent) => {
                            // Convert playbackRate to number to fix type error
                            const rate = Number(playbackRate.value);
                            event.target.setPlaybackRate(rate);

                            if (event.data === YT.PlayerState.PLAYING) {
                                // Check if this event was triggered by our play() call or by direct user interaction
                                const currentTime = Date.now();
                                const timeSinceLastPlay = currentTime - lastPlayTimestamp;

                                // If there's been a recent play call (within 1 second), this is likely from our code
                                // Otherwise, it's a direct user interaction with the iframe
                                const isFromPlayCall = timeSinceLastPlay < 1000;

                                // Update state based on who triggered the play
                                if (id === currentlyPlayingId || !isFromPlayCall) {
                                    playerState.value = PlayerState.PLAYING;

                                    // Update registry state
                                    const currentPlayerData = playerRegistry.get(id);
                                    if (currentPlayerData) {
                                        currentPlayerData.state = PlayerState.PLAYING;
                                        playerRegistry.set(id, currentPlayerData);
                                    }

                                    // If user clicked directly on the embed, notify all instances
                                    if (!isFromPlayCall) {
                                        notifyVideoPlaying(id);
                                    }
                                }
                            } else if (event.data === YT.PlayerState.PAUSED) {
                                playerState.value = PlayerState.PAUSED;

                                // Update registry state
                                const currentPlayerData = playerRegistry.get(id);
                                if (currentPlayerData) {
                                    currentPlayerData.state = PlayerState.PAUSED;
                                    playerRegistry.set(id, currentPlayerData);
                                }
                            }
                        },
                    },
                });
                playerRegistry.set(id, playerData);

                // Watch for playback rate changes
                watch(playbackRate, () => {
                    const updatedPlayerData = playerRegistry.get(id);
                    if (updatedPlayerData?.player) {
                        const rate = Number(playbackRate.value);
                        updatedPlayerData.player.setPlaybackRate(rate);
                    }
                });
            });
        } catch (error) {
            console.error("Error initializing YouTube player:", error);
            playerState.value = PlayerState.UNINITIALIZED;

            // Update registry state
            const playerData = playerRegistry.get(id);
            if (playerData) {
                playerData.state = PlayerState.UNINITIALIZED;
                playerRegistry.set(id, playerData);
            }
        }
    }

    function play(timestamp: number): void {
        const playerData = playerRegistry.get(id);

        // Set a timestamp to track the most recent play action
        lastPlayTimestamp = Date.now();

        // Notify all instances that this video is now playing
        notifyVideoPlaying(id);

        if (!playerData?.player) {
            // Player not initialized yet, add iframe which will trigger initialization
            iframe.value?.addIframe();

            // Create a pending play request
            const pendingRequest = {
                timestamp,
                attempts: 0,
                maxAttempts: 50,
                interval: null as NodeJS.Timeout | null,
            };

            // Store pending play request in registry
            if (playerData) {
                playerData.pendingPlay = {
                    timestamp,
                    interval: null
                };
                playerRegistry.set(id, playerData);
            }

            // Check every 100ms for 5 seconds if player is ready
            pendingRequest.interval = setInterval(() => {
                const currentPlayerData = playerRegistry.get(id);

                // Check if player is ready
                if (currentPlayerData?.player && currentPlayerData.state === PlayerState.READY) {
                    try {
                        // Make sure this is still the video we want to play
                        if (currentlyPlayingId !== id) {
                            // Another video was selected to play while waiting, abort
                            if (pendingRequest.interval) {
                                clearInterval(pendingRequest.interval);
                            }
                            // Clear pending play request
                            if (currentPlayerData.pendingPlay) {
                                currentPlayerData.pendingPlay = null;
                                playerRegistry.set(id, currentPlayerData);
                            }
                            return;
                        }

                        // Update state before playing to avoid race conditions
                        playerState.value = PlayerState.PLAYING;
                        currentPlayerData.state = PlayerState.PLAYING;
                        playerRegistry.set(id, currentPlayerData);

                        // Start playback
                        currentPlayerData.player.seekTo(timestamp, true);
                        currentPlayerData.player.playVideo();

                        // Clear pending play request
                        if (currentPlayerData.pendingPlay) {
                            currentPlayerData.pendingPlay = null;
                            playerRegistry.set(id, currentPlayerData);
                        }

                        // Clear interval
                        if (pendingRequest.interval) {
                            clearInterval(pendingRequest.interval);
                        }
                    } catch (error) {
                        console.error("Error playing video after initialization:", error);

                        // Clear interval if max attempts reached
                        if (++pendingRequest.attempts >= pendingRequest.maxAttempts) {
                            if (pendingRequest.interval) {
                                clearInterval(pendingRequest.interval);
                            }

                            // Clear pending play request
                            if (currentPlayerData.pendingPlay) {
                                currentPlayerData.pendingPlay = null;
                                playerRegistry.set(id, currentPlayerData);
                            }

                            console.error("Timeout waiting for player to be ready");
                        }
                    }
                } else if (++pendingRequest.attempts >= pendingRequest.maxAttempts) {
                    // Clear interval if max attempts reached
                    if (pendingRequest.interval) {
                        clearInterval(pendingRequest.interval);
                    }

                    // Clear pending play request
                    if (currentPlayerData?.pendingPlay) {
                        currentPlayerData.pendingPlay = null;
                        playerRegistry.set(id, currentPlayerData);
                    }

                    console.error("Timeout waiting for player to be ready");
                }
            }, 100);

            // Store interval reference
            if (playerData?.pendingPlay) {
                playerData.pendingPlay.interval = pendingRequest.interval;
                playerRegistry.set(id, playerData);
            }

            return;
        }

        try {
            // Update state before playing to avoid race conditions
            playerState.value = PlayerState.PLAYING;
            playerData.state = PlayerState.PLAYING;
            playerRegistry.set(id, playerData);

            // Player exists, start playback
            playerData.player.seekTo(timestamp, true);
            playerData.player.playVideo();
        } catch (error) {
            console.error("Error playing video:", error);

            // Revert state if error
            playerState.value = PlayerState.PAUSED;
            playerData.state = PlayerState.PAUSED;
            playerRegistry.set(id, playerData);
        }
    }

    function pause(): void {
        const playerData = playerRegistry.get(id);
        if (playerData?.player) {
            try {
                playerData.player.pauseVideo();
                playerState.value = PlayerState.PAUSED;

                // Update registry
                playerData.state = PlayerState.PAUSED;
                playerRegistry.set(id, playerData);
            } catch (error) {
                console.error("Error pausing video:", error);
            }
        }
    }

    function stop(): void {
        const playerData = playerRegistry.get(id);
        if (playerData?.player) {
            try {
                playerData.player.stopVideo();
                playerState.value = PlayerState.PAUSED;

                // Update registry
                playerData.state = PlayerState.PAUSED;
                playerRegistry.set(id, playerData);

                if (currentlyPlayingId === id) {
                    currentlyPlayingId = null;
                }
            } catch (error) {
                console.error("Error stopping video:", error);
            }
        }
    }

    // Watch for player state changes and update registry
    watch(playerState, (newState) => {
        const playerData = playerRegistry.get(id);
        if (playerData) {
            playerData.state = newState;
            playerRegistry.set(id, playerData);
        }
    });

    return {
        iframe,
        onIframeAdded,
        play,
        pause,
        stop,
        playerState,
    }
}
