<template>
  <button 
      @click="toggleFollow" 
      :disabled="isLoading"
      class="rounded-full px-4 py-1 text-sm font-semibold transition-colors"
      :class="{
        'bg-primary hover:bg-primary-dark text-white': isFollowing,
        'bg-gray-200 hover:bg-gray-300 text-gray-700': !isFollowing,     
        'opacity-50 cursor-not-allowed': isLoading
      }"
  >
      <span v-if="isLoading">
          Loading...
      </span>
      <span v-else>
          {{ isFollowing ? 'Unfollow' : 'Follow' }}
      </span>
  </button>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import { ScrollState } from '@/Enum/ScrollState'

const props = defineProps<{
  podcasterId: string;
  initialIsFollowing: boolean;
  isLoading?: boolean;
}>();

const emit = defineEmits<{
  (e: 'follow-toggled', podcasterId: string): void;
}>();

const isFollowing = ref(props.initialIsFollowing);
const scrollState = ref<ScrollState>()

// Watch for changes in initialIsFollowing prop
watch(
  () => props.initialIsFollowing,
  (newValue) => {
    isFollowing.value = newValue;
  }
);

const toggleFollow = async () => {
  if (props.isLoading) return;
  
  try 
  {
    const newState = !isFollowing.value;
    isFollowing.value = newState;
    emit('follow-toggled', props.podcasterId);
  } 
  catch (e: any) {
    isFollowing.value = !isFollowing.value;
    scrollState.value = ScrollState.Failed
  }
}
</script>
