<template>
    <div class="mb-0 mt-3 leading-5 text-slate-500 md:mt-5">
        <div v-if="clip.selected && !clip.rejected" class="mb-8 text-slate-700">
            <template v-if="!hideAvatarName">
                <div class="mb-2 flex items-center">
                    <div class="relative inline-flex flex-shrink-0 text-slate-500">
                        <div class="isolate flex -space-x-3 overflow-hidden">
                            <span
                                class="hover:cursor-pointer"
                                @click="viewPodcaster(guest)"
                                v-for="guest in avatarsGuests"
                                :key="'guestAvatars-' + guest.id"
                            >
                                <img
                                    :alt="guest.name"
                                    class="relative z-30 inline-block size-7 rounded-full ring-2 ring-white"
                                    height="32"
                                    width="32"
                                    :src="guest.picture_url"
                                />
                            </span>
                        </div>
                    </div>
                    <div class="prose ml-2 text-sm font-bold">
                        <span
                            class="font-bold no-underline hover:cursor-pointer"
                            @click="viewPodcaster(guest)"
                            v-for="(guest, key) in visibleGuests"
                            :key="'visibleGuests-' + guest.id"
                        >
                            {{ guest.name }}<span v-if="key != visibleGuests.length - 1">, </span>
                        </span>
                        <span v-if="guestCount > 0"> (+{{ guestCount }})</span>
                    </div>
                </div>
            </template>

            <div :id="'clip_' + clip.id" ref="youtubeRef" class="aspect-video overflow-hidden rounded-xl">
                <LiteYouTubeEmbed
                    :id="clip.podcast.external_id"
                    title="Video"
                    ref="iframe"
                    :params="'autoplay=0&start=' + clip.timestamp_with_context"
                    @iframeAdded="onIframeAdded"
                />
            </div>

            <div class="mb-3 mt-6 lg:mt-7">
                <Quote :clip="clip" :key="clip.podcast.external_id" @click="playVideo(clip.timestamp_with_context)" />
            </div>

            <div
                class="mx-0.5 flex items-center justify-between rounded-full border px-3 py-1.5 text-xs"
                v-if="clip.failed"
                :class="{
                    'border-red-100 bg-red-50 text-red-900': !clip.quote,
                    'border-amber-100 bg-amber-50 text-amber-900': clip.quote,
                }"
            >
                <div class="flex items-center">
                    <div class="mr-1.5">
                        <ExclamationTriangleIcon class="h-4 text-amber-600" />
                    </div>
                    <div v-if="!clip.quote">No quote could be extracted.</div>
                    <div v-else>Quote timestamp couldn't be extracted, using clip start timestamp instead.</div>
                </div>
                <button @click="retry" class="text-amber-600 hover:text-amber-800">
                    <ArrowPathIcon class="h-4" :class="{ 'animate-spin': retrying }" />
                </button>
            </div>

            <div v-else-if="!clip.quote || clip.timestamp === 0" class="mx-1 flex items-start justify-between space-x-2 text-xs text-gray-700">
                <div class="flex items-center">
                    <div class="mr-1.5">
                        <ArrowPathIcon class="h-4 animate-spin text-primary-600" />
                    </div>
                    <div v-if="!clip.quote">Quoting the clip.</div>
                    <div v-else-if="clip.timestamp === 0">Finding timestamp of quote.</div>
                </div>
            </div>

            <div v-else class="mx-1 flex items-start justify-between space-x-2 text-xs text-gray-700">
                <div class="flex items-start space-x-3">
                    <button v-if="!shared || clip.caption" class="flex items-start space-x-1" @click="caption">
                        <span class="shrink-0">
                            <ArrowPathIcon v-if="captioning" class="h-4 animate-spin text-primary-600" />
                            <InformationCircleIcon v-else class="h-4 text-primary-600" />
                        </span>
                        <span class="text-left" v-if="clip.caption">
                            <em>{{ clip.caption }}</em>
                        </span>
                        <span v-else-if="!captioning">Click to summarize clip</span>
                        <span v-else-if="captioning">Summarizing clip</span>
                    </button>

                    <button class="flex items-start space-x-1" @click="viewFullVideo">
                        <span class="shrink-0">
                            <InformationCircleIcon class="h-4 text-primary-600" />
                        </span>
                        <span>Click to view full video</span>
                    </button>
                    <!-- <div v-else></div> -->
                </div>

                <div class="flex items-center space-x-2">
                    <button v-if="!shared" :id="'vote-true-'+clip.id" :aria-label="'vote-true-'+clip.id" class="hover:text-green-600" @click="voteClip(clip.id, true)">
                        <HandThumbUpIcon class="h-4" />
                    </button>
                    <button v-if="!shared" :id="'vote-false-'+clip.id" :aria-label="'vote-false-'+clip.id" class="hover:text-red-600" @click="voteClip(clip.id, false)">
                        <HandThumbDownIcon class="h-4" />
                    </button>
                    <span class="font-semibold" title="AI score">{{ (clip.score * 100).toFixed(2) }}%</span>
                    <button v-if="!shared" :id="'share-'+clip.id" :aria-label="'share-'+clip.id" class="hover:text-primary" title="Share" @click="shareClip">
                        <ShareIcon class="h-4" />
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import Quote from '@/Components/Quote.vue'
import useYoutube from '@/Composables/useYoutube'
import { useConversationStore } from '@/Stores/conversation'
import type { ClipResource, PodcasterResource } from '@/types'
import { ArrowPathIcon, ExclamationTriangleIcon, HandThumbDownIcon, HandThumbUpIcon, ShareIcon } from '@heroicons/vue/24/outline'
import { InformationCircleIcon } from '@heroicons/vue/24/solid'
import LiteYouTubeEmbed from 'vue-lite-youtube-embed'
import 'vue-lite-youtube-embed/style.css'
import { computed, inject, ref } from 'vue'

import { copyToClipboard } from '@/Utils/Clipboard'
import { toast } from 'vue3-toastify'
import { storeToRefs } from 'pinia'
import { router } from '@inertiajs/vue3'

const conversationStore = useConversationStore()
const { shared } = storeToRefs(conversationStore)
const { sendMessageToApi, retryClip, captionClip, voteClip, shareConversation } = conversationStore
const route = inject('route')

const props = defineProps<{
    clip: ClipResource
    question: string | null
    hideAvatarName?: boolean | null
}>()

const retrying = ref<Boolean>(false)
const captioning = ref<Boolean>(false)
const youtubeRef = ref<HTMLElement>()
const visibleGuestsCount = 3

const { iframe, play, pause, stop, onIframeAdded, playerState } = useYoutube(props.clip.id)

const avatarsGuests = computed(() => {
    return props.clip.podcast.activeGuests.filter((guest: PodcasterResource) => null !== guest.picture_url && '' !== guest.picture_url)
})

const visibleGuests = computed(() => {
    return props.clip.podcast.activeGuests.slice(0, visibleGuestsCount)
})

const guestCount = computed(() => {
    return props.clip.podcast.activeGuests.length - visibleGuestsCount
})

const playVideo = (timestamp: number) => {
    try {
        // If already playing, just seek to new position
        if (playerState.value === 'playing') {
            play(timestamp)
        } else {
            // Ensure component is visible by scrolling to it
            youtubeRef.value?.scrollIntoView({ block: 'center', behavior: 'smooth' })

            // Start playback
            play(timestamp)
        }
    } catch (error) {
        console.error("Error playing video:", error);
    }
}

const retry = async () => {
    retrying.value = true

    await retryClip(props.clip.id, () => (retrying.value = false))
}

const caption = async () => {
    captioning.value = true

    await captionClip(props.clip.id, () => (captioning.value = false))
}

const shareClip = async () => {
    const shareId = await shareConversation(props.clip.id)

    await copyToClipboard(route('clipfinder.shared.show', shareId))

    toast.success('Copied shareable link to clipboard', {
        autoClose: 2000,
    })
}

const viewPodcaster = async (guest: PodcasterResource) => {
    router.visit(route('clipfinder.podcasters.show', [guest.slug]))
}

const viewFullVideo = () => {
    const podcasterSlug = props.clip.podcast.activeGuests[0]?.slug
    const videoId = props.clip.podcast.external_id

    if (podcasterSlug && videoId) {
        router.visit(route('clipfinder.podcaster.podcasts.video', [podcasterSlug, videoId]))
    }
}
</script>
