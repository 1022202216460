<template>
    <SlideOver title="Find clips from:" :open="open" @close="onClose" @submit="save">
        <div class="mt-5 divide-y divide-gray-200 px-4 sm:px-6">
            <div>
                <div>
                    <ToggleSlider
                    v-model="selectAll"
                    @update:modelValue="handleToggle"
                    @onLabelClick="startNewChat"
                    :onLabel="`Remove all ${podcasters.length} channels`"
                    :offLabel="`Select all ${podcasters.length} channels`"
                    @on-label-click="startNewChat"
                    />
                </div>
                <div class="flex items-end justify-between space-x-4">
                    <div class="flex flex-1 rounded-md shadow-sm">
                        <div class="relative flex flex-grow items-stretch focus-within:z-10">
                            <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                <MagnifyingGlassIcon class="h-5 w-5 text-gray-400" />
                            </div>
                            <input
                                v-model="query"
                                type="search"
                                name="query"
                                id="query"
                                class="block w-full rounded-lg border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                                placeholder="Search..."
                            />
                        </div>
                    </div>
                </div>
                <ul role="list" class="mt-4 flex gap-2 flex-wrap grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4">
<!--                    Can Delete to always be true-->
                    <PodcasterSelector
                        v-for="podcaster in filteredPodcasters"
                        :key="podcaster.id"
                        :podcaster="podcaster"
                        :selected="form.podcasters.includes(podcaster.id)"
                        :mobile="mobile"
                        @add="add"
                        @remove="remove"
                        @startNewChat="startNewChatWithPodcaster"
                    />
                </ul>
            </div>
        </div>

        <template #buttons>
            <button
                @click="startNewChat(false)"
                role="button"
                type="button"
                class="ml-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-primary-700 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
                <PencilSquareIcon class="h-5 w-10" />
            </button>
            &nbsp; &nbsp;
            <button
                type="button"
                class="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                @click="open = false"
            >
                Cancel
            </button>
            <button
                type="submit"
                class="ml-3 inline-flex justify-center rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
            >
                Save
            </button>
        </template>
        <WarningModal v-if="showWarningModal" @close="closeWarningModal" />
        <ConfirmationModal v-if="showModal" @close="closeModal" @confirm="startNewChat" />
    </SlideOver>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { router } from '@inertiajs/vue3'
import { usePodcasterStore } from '@/Stores/podcaster'
import { MagnifyingGlassIcon } from '@heroicons/vue/24/outline'
import { PencilSquareIcon  } from '@heroicons/vue/24/solid'
import { computed, inject, reactive, ref, watch } from 'vue'
import type { PodcasterResource } from '@/types'
import { useConversationStore } from '@/Stores/conversation'
import PodcasterSelector from '@/Layouts/Partials/PodcasterSelector.vue'
import SlideOver from '@/Components/SlideOver.vue'
import ToggleSlider from '@/Layouts/Partials/ToggleSlider.vue'
import WarningModal from '@/Components/WarningModal.vue';
import ConfirmationModal from '@/Components/ConfirmationModal.vue';
import { useMenuStore } from '@/Stores/menu'

const menuStore = useMenuStore()

const { mobile } = storeToRefs(menuStore)

const route = inject('route')

const podcasterStore = usePodcasterStore()
const conversationStore = useConversationStore()

const { selectPodcasters } = podcasterStore
const { updateParticipants } = conversationStore

const { conversationId } = storeToRefs(conversationStore)
const { open, podcasters, selectedPodcastersIds } = storeToRefs(podcasterStore)

interface ConversationForm {
    podcasters: string[]
    year?: number
}

const query = ref('')
const selectAll = ref(false)
const form: ConversationForm = reactive({
    podcasters: JSON.parse(JSON.stringify(selectedPodcastersIds.value)),
})
const MAX_SLUGS = 6

const showWarningModal = ref(false)
const showModal = ref(false)

watch(
    () => open.value,
    (newValue: Boolean, oldValue: Boolean) => {
        if (newValue === true && oldValue === false) {
            form.podcasters = JSON.parse(JSON.stringify(selectedPodcastersIds.value))
            selectAll.value = form.podcasters.length === podcasters.value.length
            showWarningModal.value = false
            showModal.value = false
        }
    }
)

const invertedSelection = () => {
    var value = true

    if (form.podcasters.length === podcasters.value.length) {
        value = false
        handleToggle(value)
    }

    return value;
}

const updateUrlWithSlugs = (selectedPodcasters: string[]) => {
    const selectedPodcasterObjects = podcasters.value.filter(p => selectedPodcasters.includes(p.id))
    const url = ['/', selectedPodcasters.length == 1 ? selectedPodcasterObjects[0].slug : 'conversations/start'].join('')

    window.history.pushState({}, '', url)
}

const handleToggle = (value: boolean) => {
    if (value) {
        form.podcasters = podcasters.value.map(p => p.id)
    } else {
        form.podcasters = []
    }
}

const add = (podcaster: PodcasterResource) => {
    if (!form.podcasters.includes(podcaster.id)) {
        form.podcasters.push(podcaster.id)
    }

    updateSelectAllState()
}

const remove = (podcaster: PodcasterResource) => {
    if (form.podcasters.length <= 1) {
        handleToggle(true)
        updateSelectAllState()
    } else if (invertedSelection()) {
        const index = form.podcasters.findIndex((pid: string) => pid === podcaster.id)
        if (index >= 0) {
            form.podcasters.splice(index, 1)
        }

        updateSelectAllState()
    } else {
        add(podcaster)
    }
}

const updateSelectAllState = () => {
    selectAll.value = form.podcasters.length === podcasters.value.length
}

const save = async () => {
    if (form.podcasters.length < 1) {
        showWarningModal.value = true
        return
    }

    if (!conversationId.value) {
        await startNewChat()
        return
    } else {
        await updateParticipants(form.podcasters)
        selectPodcasters(form.podcasters)

        open.value = false
    }
}

const startNewChatWithPodcaster = (podcaster?: PodcasterResource) => {
    if (podcaster) {
        handleToggle(false)
        add(podcaster)
    }

    open.value = false

    startNewChat()
}

const startNewChat = async (useAllPodcasters: boolean = false) => {
    if (form.podcasters.length < 1 && !useAllPodcasters) {
        showWarningModal.value = true
        return
    }

    conversationId.value = null
    open.value = false

    if (useAllPodcasters) {
        handleToggle(true)
    }

    selectPodcasters(form.podcasters)
    updateUrlWithSlugs(form.podcasters)

    var endpointUrl = form.podcasters.length === 1 ? `` : route('clipfinder.conversations.create')

    // Use router.visit with the full list or selection
    router.visit(endpointUrl, {
        preserveState: form.podcasters.length > 1,
        replace: false,
    });
};

const onClose = () => {
    if (form.podcasters.length > 0) {
        showModal.value = true
    } else {
        open.value = false
    }
}

const closeModal = () => {
    showModal.value = false
    open.value = false
}

const closeWarningModal = () => {
    showWarningModal.value = false
}

const filteredPodcasters = computed(() => {
    const result =
        query.value === ''
            ? podcasters.value
            : podcasters.value.filter((podcaster: PodcasterResource) => {
                  return podcaster.name.toLowerCase().includes(query.value.toLowerCase())
              })
    // Sorting the result array by name in ascending order
    return result.sort((a: PodcasterResource, b: PodcasterResource) => {
        return a.name.localeCompare(b.name)
    })
})

defineExpose({
    startNewChat,
})
</script>
