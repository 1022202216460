<template>
    <ContentLayout>
        <PageTitle>
            Start listening to <br />
            <PageTitleHighlight>{{ category.name }}</PageTitleHighlight>
            clips
        </PageTitle>

        <div class="flex flex-col items-center overflow-auto px-4 scrollbar-hide">
            <div class="max w-full">
                <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4">
                    <PodcasterCard v-for="podcaster in podcasters" :key="podcaster.id" :podcaster="podcaster" :category="category" />
                </div>
            </div>
        </div>
    </ContentLayout>
</template>
<script setup lang="ts">
import type { CategoryResource } from '@/types'
import PodcasterCard from '@/Components/PodcasterCard.vue'
import ContentLayout from '@/Layouts/ContentLayout.vue'
import SearchInput from '@/Components/SearchInput.vue'
import { inject } from 'vue'
import { type Ref, ref } from 'vue'
import { Link, router } from '@inertiajs/vue3'
import { useConversationStore } from '@/Stores/conversation'
import { usePodcasterStore } from '@/Stores/podcaster'
import { storeToRefs } from 'pinia'
import { onMounted } from 'vue'
import PageTitle from '../Podcaster/PageTitle.vue'
import PageTitleHighlight from '../Podcaster/PageTitleHighlight.vue'
import { computed } from 'vue'

const route = inject('route')

const question: Ref<string | null> = ref(null)
const errors = ref<{ string: string[] }[]>([])
const failed = ref<Boolean>(false)

const submit = () => {
    submitQuery(question.value || '')
}

const podcasterStore = usePodcasterStore()
const { podcasters } = storeToRefs(podcasterStore)

const { category, podcast_count, hours } = defineProps<{
    category: CategoryResource
    podcast_count: Number
    hours: Number
}>()

onMounted(() => {
    podcasterStore.fetchCategoryPodcasters(category.id)
})

const conversationStore = useConversationStore()
const { sendMessage } = conversationStore

const submitQuery = async (query: string) => {
    try {
        const conversation = await sendMessage(query, true)
        router.visit(route('clipfinder.conversations.show', [conversation]))
    } catch (e: any) {
        failed.value = true
        errors.value = e.errors || []
    }
}

const invisiblePodcastersCount = computed(() => Math.max(0, podcasters.value.length - 6))
</script>
