<template>
    <div class="mb-4" ref="youtubeRef">
        <!-- Question/Message Part -->
        <template v-if="showMessage && (message || customMessageContent)">
            <ChatMessage
                :align="messageAlign"
                :avatar="messageAvatar"
                :date="messageDate">
                <ChatBubble :align="messageAlign" :primary="messagePrimary" class="flex flex-col items-start mt-1">
                    <div class="text-sm font-bold -mt-1" v-if="messageSenderName">
                        {{ messageSenderName }}
                    </div>
                    <a :href="getHref" class="not-prose" :class="{'text-sm': messageSenderName}">
                        <slot name="message-content">
                            {{ message }}<span v-if="type === 'clip' && clip && clip.is_topic">&nbsp;|&nbsp;{{ timestampToString(clip.timestamp_with_context) }}</span>
                        </slot>
                    </a>
                </ChatBubble>
            </ChatMessage>
        </template>

        <!-- Media Content (Clip or Podcast) -->
        <template v-if="type === 'clip' && clip">
            <div class="mb-0 mt-3 leading-5 text-slate-500 md:mt-5">
                <div v-if="clip.selected && !clip.rejected" class="mb-8 text-slate-700">
                    <!-- Podcaster avatars and names section -->
                    <template v-if="!hideAvatarName && clip.podcast?.activeGuests?.length > 0">
                        <GuestAvatarStack
                            :guests="clip.podcast.activeGuests"
                            :visibleGuestsCount="visibleGuestsCount"
                            @guest-click="viewPodcaster"
                        />
                    </template>

                    <!-- YouTube embed for clip -->
                    <div :id="'clip_' + clip.id" class="aspect-video overflow-hidden rounded-xl">
                        <LiteYouTubeEmbed
                            :id="clip.podcast.external_id"
                            title="Video"
                            ref="iframe"
                            :params="'autoplay=0&start=' + clip.timestamp_with_context"
                            @iframeAdded="onIframeAdded"
                        />
                    </div>

                    <!-- Quote display -->
                    <div class="mb-3 mt-6 lg:mt-7">
                        <Quote :hideFullSummary="hideFullSummary" :clip="clip" :key="clip.podcast.external_id" @click="onClick(clip.timestamp_with_context)" />
                    </div>

                    <!-- Error states -->
                    <div
                        class="mx-0.5 flex items-center justify-between rounded-full border px-3 py-1.5 text-xs"
                        v-if="clip.failed"
                        :class="{
                            'border-red-100 bg-red-50 text-red-900': !clip.quote,
                            'border-amber-100 bg-amber-50 text-amber-900': clip.quote,
                        }"
                    >
                        <div class="flex items-center">
                            <div class="mr-1.5">
                                <ExclamationTriangleIcon class="h-4 text-amber-600" />
                            </div>
                            <div v-if="!clip.quote">No quote could be extracted.</div>
                            <div v-else>Quote timestamp couldn't be extracted, using clip start timestamp instead.</div>
                        </div>
                        <button @click="retry" class="text-amber-600 hover:text-amber-800">
                            <ArrowPathIcon class="h-4" :class="{ 'animate-spin': retrying }" />
                        </button>
                    </div>

                    <div v-else-if="!clip.quote || clip.timestamp === 0" class="mx-1 flex items-start justify-between space-x-2 text-xs text-gray-700">
                        <div class="flex items-center">
                            <div class="mr-1.5">
                                <ArrowPathIcon class="h-4 animate-spin text-primary-600" />
                            </div>
                            <div v-if="!clip.quote">Quoting the clip.</div>
                            <div v-else-if="clip.timestamp === 0">Finding timestamp of quote.</div>
                        </div>
                    </div>

                    <!-- Actions section -->
                    <div v-else class="mx-1 flex items-start justify-between space-x-2 text-xs text-gray-700">
                        <div class="flex items-start space-x-3">
                            <button v-if="!shared || clip.caption" class="flex items-start space-x-1" @click="caption">
                                <span class="shrink-0">
                                    <ArrowPathIcon v-if="captioning" class="h-4 animate-spin text-primary-600" />
                                    <InformationCircleIcon v-else class="h-4 text-primary-600" />
                                </span>
                                <span class="text-left" v-if="clip.caption">
                                    <em>{{ clip.caption }}</em>
                                </span>
                                <span v-else-if="!captioning">Click to summarize clip</span>
                                <span v-else-if="captioning">Summarizing clip</span>
                            </button>

                            <button class="flex items-start space-x-1" @click="viewFullVideo" v-if="!onPodcastPage()">
                                <span class="shrink-0">
                                    <InformationCircleIcon class="h-4 text-primary-600" />
                                </span>
                                <span>Click to view full video</span>
                            </button>
                        </div>

                        <div class="flex items-center space-x-2">
                            <button v-if="!shared" :id="'vote-true-'+clip.id" :aria-label="'vote-true-'+clip.id" class="hover:text-green-600" @click="voteClip(clip.id, true)">
                                <HandThumbUpIcon class="h-4" />
                            </button>
                            <button v-if="!shared" :id="'vote-false-'+clip.id" :aria-label="'vote-false-'+clip.id" class="hover:text-red-600" @click="voteClip(clip.id, false)">
                                <HandThumbDownIcon class="h-4" />
                            </button>
                            <span v-if="messageAvatar != 'topic'" class="font-semibold" title="AI score">{{ (clip.score * 100).toFixed(2) }}%</span>
                            <button v-if="!shared" :id="'share-'+clip.id" :aria-label="'share-'+clip.id" class="hover:text-primary" title="Share" @click="shareClip">
                                <ShareIcon class="h-4" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <!-- Full Podcast Display -->
        <template v-else-if="type === 'podcast' && podcast">
            <div class="mt-4 mb-8">
                <!-- Podcaster avatars and names section for podcast view -->
                <GuestAvatarStack
                    v-if="podcast.activeGuests && podcast.activeGuests.length > 0"
                    :guests="podcast.activeGuests"
                    :visibleGuestsCount="visibleGuestsCount"
                    @guest-click="viewPodcaster"
                />

                <!-- YouTube embed for podcast -->
                <div class="relative w-full overflow-hidden rounded-lg">
                    <div class="aspect-w-16 aspect-h-9 w-full">
                        <LiteYouTubeEmbed
                            :id="podcast.external_id"
                            :title="podcast.title"
                            :params="'autoplay=0'"
                            title="Video"
                            ref="iframe"
                            @iframeAdded="onIframeAdded"
                        />
                    </div>
                </div>

                <!-- Quote/Summary display -->
                <div class="mb-3 mt-6 lg:mt-7">
                    <Quote :hideFullSummary="hideFullSummary" :podcast="podcast" :quote="podcast.summary" :title="podcast.title" :date="podcast.date" :key="podcast.external_id" @click="onClick()" />
                </div>
            </div>
        </template>
    </div>
</template>

<script setup lang="ts">
import { timestampToString } from '@/Utils/String'
import ChatMessage from '@/Components/ChatMessage.vue'
import ChatBubble from '@/Components/ChatBubble.vue'
import Quote from '@/Components/Quote.vue'
import GuestAvatarStack from '@/Components/GuestAvatarStack.vue'
import useYoutube from '@/Composables/useYoutube'
import { useConversationStore } from '@/Stores/conversation'
import type { ClipResource, PodcasterResource, PodcastResource } from '@/types'
import { ArrowPathIcon, ExclamationTriangleIcon, HandThumbDownIcon, HandThumbUpIcon, ShareIcon } from '@heroicons/vue/24/outline'
import { InformationCircleIcon } from '@heroicons/vue/24/solid'
import LiteYouTubeEmbed from 'vue-lite-youtube-embed'
import 'vue-lite-youtube-embed/style.css'
import { computed, onMounted, inject, ref } from 'vue'
import { copyToClipboard } from '@/Utils/Clipboard'
import { toast } from 'vue3-toastify'
import { storeToRefs } from 'pinia'
import { router } from '@inertiajs/vue3'
import moment from 'moment/moment'
import useApi from '@/Composables/useApi'

const conversationStore = useConversationStore()
const { shared } = storeToRefs(conversationStore)
const { retryClip, captionClip, voteClip, shareConversation } = conversationStore
const route = inject('route')
const { postJson } = useApi()

const props = defineProps({
    // Common properties
    type: {
        type: String,
        required: true,
        validator: (value) => ['clip', 'podcast', 'message'].includes(value)
    },
    hideAvatarName: {
        type: Boolean,
        default: false
    },
    visibleGuestsCount: {
        type: Number,
        default: 3
    },
    hideFullSummary: {
        type: Boolean,
        default: false
    },

    // Message properties
    showMessage: {
        type: Boolean,
        default: true
    },
    message: {
        type: String,
        default: null
    },
    messageAlign: {
        type: String,
        default: 'left',
        validator: (value) => ['left', 'right'].includes(value)
    },
    messageAvatar: {
        type: String,
        default: 'anonymous'
    },
    messageDate: {
        type: String,
        default: () => moment().toISOString()
    },
    messagePrimary: {
        type: Boolean,
        default: true
    },
    messageSenderName: {
        type: String,
        default: null
    },
    customMessageContent: {
        type: Boolean,
        default: false
    },

    // Clip properties
    clip: {
        type: Object as () => ClipResource,
        default: null
    },

    // Podcast properties
    podcast: {
        type: Object as () => PodcastResource,
        default: null
    }
})

// Clip related refs and functions
const retrying = ref<Boolean>(false)
const captioning = ref<Boolean>(false)
const youtubeRef = ref<HTMLElement>()

const podcast = computed(() => {
    return props.type === 'clip' ? props.clip.podcast : props.podcast
})

const videoReferenceId = computed(() => {
    return props.type === 'clip' ? props.clip?.id : props.podcast?.external_id
})

const { iframe, play, pause, stop, onIframeAdded, playerState } = useYoutube(videoReferenceId.value)

onMounted(() => {
    if (onPodcastPage() && props.type === 'podcast' && podcast && podcast.value && !podcast.value.has_summary) {
        console.log('Summarizing podcast')
        summarizePodcast()
    }
})

const onClick = (timestamp: number|0) => {
    if (props.type === 'clip') {
        playVideo(timestamp)
    } else if (props.type === 'podcast') {
        if (!onPodcastPage()) {
            viewFullVideo()
        } else if (podcast && podcast.value && !podcast.value.has_summary) {
            summarizePodcast()
        } else {
            playVideo(0)
        }
    }
}

const onPodcastPage = () => {
    return window.location.pathname.includes('/podcasts/video/')
}

const playVideo = (timestamp: number) => {
    try {
        // If already playing, just seek to new position
        if (playerState.value === 'playing') {
            play(timestamp)
        } else {
            // Ensure component is visible by scrolling to it
            youtubeRef.value?.scrollIntoView({ block: 'center', behavior: 'smooth' })

            // Start playback
            play(timestamp)
        }
    } catch (error) {
        console.error("Error playing video:", error);
    }
}

const retry = async () => {
    if (!props.clip) return
    retrying.value = true
    await retryClip(videoReferenceId.value, () => (retrying.value = false))
}

const caption = async () => {
    if (!props.clip) return
    captioning.value = true
    await captionClip(videoReferenceId.value, () => (captioning.value = false))
}

const shareClip = async () => {
    if (!props.clip) return
    const shareId = await shareConversation(videoReferenceId.value)
    await copyToClipboard(route('clipfinder.shared.show', shareId))
    toast.success('Copied shareable link to clipboard', {
        autoClose: 2000,
    })
}

const viewPodcaster = (guest: PodcasterResource) => {
    router.visit(route('clipfinder.podcasters.show', [guest.slug]))
}

const viewFullVideo = () => {
    if (!podcast.value?.activeGuests?.length) return

    const podcasterSlug = podcast.value.activeGuests[0]?.slug
    const videoId = podcast.value.external_id

    if (podcasterSlug && videoId) {
        router.visit(route('clipfinder.podcaster.podcasts.video', [podcasterSlug, videoId]))
    }
}

const getHref = computed(() => {
    if (props.type === 'clip' && props.clip && props.clip.message.clip_finder_conversation_id) {
        return '/conversations/' + props.clip.message.clip_finder_conversation_id
    } else if (props.type === 'podcast' && props.podcast) {
        const podcasterSlug = podcast.value.activeGuests[0]?.slug
        const videoId = podcast.value.external_id

        return '/' + podcasterSlug + '/podcasts/video/' + videoId
    }

    return ''
})

const summary_requested = ref(false)

const summarizePodcast = async () => {
    if (summary_requested.value || !(props.type === 'podcast' && podcast && podcast.value)) return

    console.log(summary_requested.value, props.type, podcast, podcast.value, !(props.type === 'podcast' && podcast && podcast.value))

    let endpoint = '/api/podcast/' + podcast.value.id + '/summarize'

    await postJson(endpoint, {})

    summary_requested.value = true

    toast.success('Podcast summary requested. Background process started and will soon be finished.', {})

    setTimeout(() => {
        router.visit('')
    }, 15000)
}
</script>
