<template>
    <ContentLayout>
        <PageTitle>
            Select your
            <PageTitleHighlight>favorite topics</PageTitleHighlight>
            <br />
            and start listening
        </PageTitle>

        <div class="mt-6 px-6">
            <div v-for="parentCategory in parentCategories" :key="parentCategory.id" class="space-y-6">
                <div class="mb-4 flex items-center justify-between">
                    <h2 class="text-2xl font-bold text-gray-900 md:text-3xl">
                        {{ parentCategory.name }}
                    </h2>
                </div>

                <div class="my-3"></div>
            </div>
        </div>
    </ContentLayout>
</template>

<script setup lang="ts">
import ContentLayout from '@/Layouts/ContentLayout.vue'
import PageTitle from '@/Pages/Podcaster/PageTitle.vue'
import PageTitleHighlight from '@/Pages/Podcaster/PageTitleHighlight.vue'
import { onMounted } from 'vue'
import { useCategoryStore } from '@/Stores/category'
import { storeToRefs } from 'pinia'

const categoryStore = useCategoryStore()
const { categories, parentCategories } = storeToRefs(categoryStore)

const submit = () => {
    // Implement category search logic
}

onMounted(() => {
    categoryStore.fetchAllCategories()
})
</script>
