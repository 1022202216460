<template>
    <div class="flex justify-center w-full mx-auto" ref="clipScrollContainer">
        <div class="sm:max-w-xl lg:w-[36rem]">
            <div v-for="(clip, index) in clips" :key="clip.message.id" class="clip-item">
                <MediaMessageComponent
                    type="clip"
                    :clip="clip"
                    :showMessage="shouldShowMessage(clip, index)"
                    :message="clip.message.original_question"
                    :messageAvatar="isFromUser(clip) ? user?.avatar_url : (isTopic(clip) ? 'topic' : 'anonymous')"
                    :messageDate="clip.podcast?.date"
                    :messageSenderName="isFromUser(clip) && getUserName(user?.id, user?.name) ? getUserName(user?.id, user?.name) : null"
                    :hideAvatarName="shouldHideAvatarName(clip, index)"
                />
            </div>

            <div ref="endOfClipScroller"></div>
            <ClipLoader v-if="isLoading" />
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted, watch, computed } from 'vue'
import { storeToRefs } from 'pinia'
import { defineClipStore } from '@/Stores/clip'
import { useUserStore } from '@/Stores/user'
import { useInfiniteScroll, OverscrollDirection, type OverscrollOptions } from '@/Composables/useScrollEvents'
import MediaMessageComponent from '@/Components/MediaMessageComponent.vue'
import ClipLoader from '@/Components/ClipLoader.vue'
import { useCategoryStore } from '@/Stores/category'
import { identity } from '@vueuse/core'

const props = defineProps({
    entityType: {
        type: String as () => 'podcast' | 'podcaster',
        required: true,
    },
    entityId: {
        type: String,
        required: true,
    },
    useUserAvatar: {
        type: Boolean,
        default: false,
    },
})

const clipStore = defineClipStore()
const { clips, isLoading } = storeToRefs(clipStore)

const userStore = useUserStore()
const { user } = storeToRefs(userStore)

const categoryStore = useCategoryStore()
const { selectedCategory } = storeToRefs(categoryStore)

const clipScrollContainer = ref<HTMLElement | null>(null)
const endOfClipScroller = ref<HTMLElement | null>(null)

const isFromUser = (clip) => {
    return clip.user_id == user?.value?.id && clip.user_id != null
}

const isTopic = (clip) => {
    return clip.is_topic
}

/**
 * Determine if we should show the message for a clip
 */
const shouldShowMessage = (clip, index) => {
    if (index === 0) return true

    return clip.message.id !== clips.value[index - 1].message.id
}

/**
 * Determine if we should hide the avatar name/stack
 */
const shouldHideAvatarName = (clip, index) => {
    if (index === 0) return false

    const prevClip = clips.value[index - 1]

    // If same podcast AND same question, hide avatar name
    if (
        clip.podcast.id === prevClip.podcast.id &&
        clip.message.id === prevClip.message.id
    ) {
        return true
    }

    // Otherwise show it
    return false
}

const loadMoreClips = () => {
    clipStore.fetchEntityClips(props.entityType, props.entityId, props.useUserAvatar ? 'for_user=true' : '')
}

const isCurrentUser = (authorId: string | undefined) => {
    return user.value?.id == authorId
}

const getUserName = (userId: string | undefined, userName: string | undefined) => {
    if (userName === null || userName === undefined) {
        return null
    } else if (isCurrentUser(userId)) {
        return 'You'
    } else {
        return userName
    }
}

onMounted(() => {
    loadMoreClips()

    if (clipScrollContainer.value && endOfClipScroller.value) {
        useInfiniteScroll(clipScrollContainer.value, endOfClipScroller.value, loadMoreClips, {
            direction: OverscrollDirection.bottom,
            rootMargin: '150px',
        } as OverscrollOptions)
    }
})
</script>
<style scoped>
.clip-item {
    position: relative;
}
</style>
