<template>
  <div class="flex items-center justify-end mb-4">
    <a
      href="#"
      class="mr-3 text-sm font-medium cursor-pointer"
      :class="modelValue ? 'text-primary-600' : 'text-gray-500'"
      @click.prevent="$emit('on-label-click')"
    >
      {{ modelValue ? onLabel : offLabel }}
    </a>
    <button 
      type="button" 
      @click="toggle"
      class="relative inline-flex items-center w-16 h-7 rounded-full transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-600"
      :class="modelValue ? 'bg-primary-600' : 'bg-gray-200'"
    >
      <span 
        class="absolute left-0 inline-block w-7 h-7 transition-transform duration-200 transform bg-white rounded-full shadow-md"
        :class="modelValue ? 'translate-x-9' : 'translate-x-0'"
      />
      <span 
        class="absolute inset-0 flex items-center text-xs font-medium"
        :class="[
          modelValue ? 'justify-start pl-2 text-white' : 'justify-end pr-2 text-gray-700',
          {'pointer-events-none': modelValue}
        ]"
      >
        {{ modelValue ? 'ON' : 'OFF' }}
      </span>
    </button>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  modelValue: boolean
  onLabel?: string
  offLabel?: string
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void
  (e: 'on-label-click'): void
}>()

const toggle = () => {
  emit('update:modelValue', !props.modelValue)
}
</script>