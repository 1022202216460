<template>
    <Head title="Podcaster Overview" />

    <ContentLayout>
        <PageTitle>
            Select your
            <PageTitleHighlight>favorite people</PageTitleHighlight>
            <br />
            and start listening
        </PageTitle>

        <div class="mt-6 lg:mt-16" ref="scroller">
            <div class="grid grid-cols-2 gap-x-2 gap-y-6 lg:grid-cols-4 lg:gap-y-12 xl:grid-cols-5">
                <div v-for="podcaster in podcasters" :key="podcaster.id">
                    <PodcasterIconWithFollowButton :podcaster="podcaster" :is-loading-follow-state="isLoadingFollowState" class="overflow-hidden" />
                </div>
            </div>
            <div ref="endOfScroller"></div>
        </div>
        <div class="relative mx-auto w-9 md:w-12" v-if="isLoading">
            <PodcastclipfinderIcon animate class="h-9 md:h-12" />
        </div>
        <FailedMessage v-if="failed" class="flex flex-col items-center">
            We couldn't process more podcasts due to an internal error. Please try again later.
        </FailedMessage>

        <div class="sticky bottom-0 z-10 mt-4 bg-white">
            <div class="mx-auto rounded-t-3xl">
                <form @submit.prevent="submit" class="relative">
                    <SearchInput :rounded-full="true" v-model="question" :disabled="isLoading" @submit="submit" :placeholder="placeholder" />
                </form>
            </div>

            <div class="relative py-3 text-center text-xs leading-4 text-gray-600 md:px-16 md:py-4">
                <span class="text-gray-600">
                    Clipfinder.com knows all the podcasts until
                    <time class="italic" datetime="{{ trainingDate }}">{{ trainingDate.toLocaleDateString() }}</time>
                </span>
            </div>
        </div>
    </ContentLayout>
</template>

<script setup lang="ts">
import { usePodcasterStore } from '@/Stores/podcaster'
import { storeToRefs } from 'pinia'
import ContentLayout from '@/Layouts/ContentLayout.vue'
import PageTitle from '@/Pages/Podcaster/PageTitle.vue'
import PageTitleHighlight from '@/Pages/Podcaster/PageTitleHighlight.vue'
import FailedMessage from '@/Components/FailedMessage.vue'
import PodcastclipfinderIcon from '@/Icons/PodcastclipfinderIcon.vue'
import { computed, inject, onMounted, type Ref, ref, watch } from 'vue'
import type { CategoryResource } from '@/types'
import { useConversationStore } from '@/Stores/conversation'
import { usePage, Head, router } from '@inertiajs/vue3'
import { defineCategoryStore, useCategoryStore } from '@/Stores/category'
import { defineUserStore, useUserStore } from '@/Stores/user'
import SearchInput from '@/Components/SearchInput.vue'
import { ScrollState } from '@/Enum/ScrollState'
import PodcasterIconWithFollowButton from '@/Components/PodcasterIconWithFollowButton.vue'

const route = inject<any>('route')
const page = usePage()

const { category } = defineProps<{
    category?: CategoryResource
    podcast_count?: Number
    hours?: Number
}>()

defineCategoryStore()
defineUserStore() // Initialize user store with auth state from page props

const conversationStore = useConversationStore()
const { sendMessage } = conversationStore
const userStore = useUserStore()
const categoryStore = useCategoryStore()
categoryStore.setSelectedCategory(category || null)
const store = usePodcasterStore()
const { podcasters, isLoading, failed } = storeToRefs(store)
const { loggedIn } = storeToRefs(userStore)
const podcasterStore = usePodcasterStore()
const { trainingDate } = storeToRefs(podcasterStore)
const scrollState = ref<ScrollState>(ScrollState.Default)

const scroller = ref<HTMLElement>()
const endOfScroller = ref<HTMLElement>()
const isLoadingFollowState = ref<boolean>(false)

const placeholder = computed(() => {
    return podcasterStore.selectedPodcasters.length === 1
        ? `Ask a question to ${podcasterStore.selectedPodcasters[0].name}...`
        : `Ask a question to ${podcasterStore.selectedPodcasters.length} podcasters...`
})

const question: Ref<string | null> = ref(null)

const submit = () => {
    submitQuery(question.value || '')
}

const submitQuery = async (query: string) => {
    try {
        const conversation = await sendMessage(query, true)
        router.visit(route('clipfinder.conversations.show', [conversation]))
    } catch (e: any) {
        scrollState.value = ScrollState.Failed
    }
}

onMounted(() => {
    // Check if user is authenticated using the store
    if (loggedIn.value) {
        podcasterStore.fetchFollowedPodcasters();
    }

    store.fetchAllPodcasters()
    store.selectAllPodcasters()
})

// Watch for category changes
watch(() => category, (newCategory) => {
    if (newCategory?.id !== categoryStore.selectedCategory?.id) {
        store.resetPodcasters()
        categoryStore.setSelectedCategory(newCategory || null)
    }
})

// Watch for authentication state changes
watch(() => loggedIn.value, (newAuthState) => {
    if (newAuthState) {
        podcasterStore.fetchFollowedPodcasters();
    }
})
</script>
