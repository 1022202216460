<template>
    <Tippy :content="tooltipContent" :placement="position" :theme="theme" class="tooltip-content" :allowHTML="true">
        <slot></slot>
    </Tippy>
</template>

<script setup>
import { defineProps, computed } from 'vue'
import { Tippy } from 'vue-tippy'

const props = defineProps({
    message: {
        type: String,
        required: false,
        default: '' 
    },
    position: {
        type: String,
        default: 'bottom',
        validator: (value) => ['top', 'bottom', 'left', 'right'].includes(value),
    },
    theme: {
        type: String,
        default: 'custom-dark',
    },
})

const tooltipContent = computed(() => {
    return props.message || ''
})
</script>

<style>
.tippy-box[data-theme~='custom-dark'] {
    background-color: rgb(31 41 55 / var(--tw-bg-opacity));
    color: white;
}
.tippy-box[data-theme~='custom-dark'][data-placement^='top'] > .tippy-arrow::before {
    border-top-color: rgb(31 41 55 / var(--tw-bg-opacity));
}
.tippy-box[data-theme~='custom-dark'][data-placement^='bottom'] > .tippy-arrow::before {
    border-bottom-color: rgb(31 41 55 / var(--tw-bg-opacity));
}
.tippy-box[data-theme~='custom-dark'][data-placement^='left'] > .tippy-arrow::before {
    border-left-color: rgb(31 41 55 / var(--tw-bg-opacity));
}
.tippy-box[data-theme~='custom-dark'][data-placement^='right'] > .tippy-arrow::before {
    border-right-color: rgb(31 41 55 / var(--tw-bg-opacity));
}
</style>
