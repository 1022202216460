<template>
    <div
        class="relative flex w-full flex-grow flex-col border border-gray-100 bg-white md:shadow-sm"
        :class="{
            'rounded-full': roundedFull,
            'rounded-md md:rounded-2xl': !roundedFull,
            'border-red-500': error,
            'border-stone-300 focus-within:border-primary focus-within:ring-1 focus-within:ring-primary': !error,
        }"
    >
        <div class="flex items-center divide-x px-2">            <textarea
            tabindex="0"
            rows="1"
            v-model="model"
            @keydown.enter.prevent="$emit('submit')"
            :disabled="disabled"
            class="m-0 w-full cursor-text resize-none appearance-none overflow-x-hidden overflow-y-hidden whitespace-nowrap rounded-none border-0 border-solid border-zinc-400 bg-transparent py-2 pr-1 text-sm focus:border-none focus:ring-0 md:py-3 md:text-base"
            :class="{
                    'opacity-50': disabled,
                }"
            :placeholder="placeholder"
        ></textarea>

            <div class="pl-3">
                <YearRangeSelector />
            </div>

            <button
                type="submit"
                title="Submit question"
                :class="{
                    'bg-gray-200 text-gray-500': disabled,
                    'bg-primary-50 text-primary-600': !disabled,
                }"
                class="inline-flex size-7 shrink-0 items-center justify-center rounded-full border-0 p-1 hover:bg-primary-600 hover:text-white focus:bg-primary-600 focus:text-white active:bg-primary-500/25 md:size-8 md:h-9 md:w-9"
            >
                <PaperAirplaneIcon v-if="!disabled" class="size-4 md:size-5" />
                <ArrowPathIcon v-else class="size-4 animate-spin md:size-5" />
            </button>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { ModelRef } from 'vue'
import { ArrowPathIcon, PaperAirplaneIcon } from '@heroicons/vue/24/outline'
import YearRangeSelector from '@/Components/YearRangeSelector.vue'

const model: ModelRef<string> = defineModel({ default: '' })

withDefaults(
    defineProps<{
        roundedFull?: boolean
        error?: boolean | undefined
        disabled?: boolean | undefined
        placeholder?: string | undefined
    }>(),
    {
        roundedFull: false,
        error: false,
        disabled: false,
        placeholder: '',
    }
)

defineEmits(['submit'])
</script>
