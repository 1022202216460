<template>
    <Layout>
        <div class="relative z-0 flex h-[100svh] w-full overflow-hidden">
            <ChatSidebar @startNewChat="startNewChat" class="max-w-xs" />
            <div class="relative z-10 h-full max-w-full flex-1 overflow-hidden bg-white shadow-[0_25px_50px_-12px_black]">

                <main class="transition-width relative h-full w-full flex-1">
                    <ChatSidebarToggle />

                    <div role="presentation" class="flex h-full flex-col" tabindex="0">
                        <ChatHeader />
                        <slot />
                    </div>
                </main>
            </div>
        </div>

        <ChatSlideOver />
        <PodcastSelectorSlideOver ref="podcastSelectorSlideOver" />
    </Layout>
</template>

<script setup lang="ts">
import Layout from '@/Layouts/Layout.vue'
import ChatSidebar from '@/Layouts/Partials/ChatSidebar.vue'
import ChatSidebarToggle from '@/Layouts/Partials/ChatSidebarToggle.vue'
import PodcastSelectorSlideOver from '@/Layouts/Partials/PodcastSelectorSlideOver.vue'
import ChatSlideOver from '@/Layouts/Partials/ChatSlideOver.vue'

import { ref } from 'vue'
import ChatHeader from '@/Components/ChatHeader.vue'

var podcastSelectorSlideOver = ref(null)

const startNewChat = () => {
    podcastSelectorSlideOver.value.startNewChat(true)
}
</script>
