<template>
  <Head :title="podcast.title" />
  <ContentLayout>
    <div
      class="absolute top-0 left-0 top-[10rem] -z-10 mt-96 flex origin-top-left translate-y-40 -rotate-90 transform-gpu opacity-20 blur-3xl sm:left-[40%] sm:-ml-96 sm:-mt-10 sm:translate-y-0 sm:rotate-0 sm:transform-gpu sm:opacity-40"
      aria-hidden="true"
    >
      <div
        class="aspect-[1154/678] w-full w-[72.125rem] bg-gradient-to-br from-primary-500/50 to-primary"
        style="
                    clip-path: polygon(
                        100% 38.5%,
                        82.6% 100%,
                        60.2% 37.7%,
                        52.4% 32.1%,
                        47.5% 41.8%,
                        45.2% 65.6%,
                        27.5% 23.4%,
                        0.1% 35.3%,
                        17.9% 0%,
                        27.7% 23.4%,
                        76.2% 2.5%,
                        74.2% 56%,
                        100% 38.5%
                    );
                "
      ></div>
    </div>

    <div class="relative">
      <h1 class="text-lg font-bold text-slate-800 md:text-3xl xl:text-4xl text-center">
        <a
          class="ml-0.5 inline-block rounded-lg border-0 bg-primary px-2 py-0.5 text-lg text-white focus:ring-2 focus:ring-primary-400 focus:ring-offset-1 md:text-3xl lg:inline xl:text-4xl"
          :href="route('clipfinder.podcasters.show', podcaster.slug)"
        >{{ podcaster.name }}</a>&nbsp;{{ decodeHTML(podcast.title) }}
      </h1>
      <!-- Video Section -->


      <div class="mt-8 w-full px-4 sm:px-6 lg:px-8 flex justify-center">
        <div class="w-full sm:max-w-xl lg:w-[36rem]">
          <!-- Video Card -->
            <MediaMessageComponent
                type="podcast"
                :podcast="podcast"
                messageAvatar="podcast"
                :messageDate="podcast.date"
            />
        </div>
      </div>

      <!-- Clips Section - Only show for current podcast -->
      <div class="mt-8 w-full px-4 sm:px-6 lg:px-8 flex justify-center mb-8">
        <div class="mt-4 mx-auto">
          <ClipComponent
            :entityType="'podcast'"
            :entityId="podcast.id"
          />
        </div>
      </div>

      <!-- Search Input Form - Show only when no clips -->
      <div class="sticky bottom-0 bg-white mx-4 leading-6 text-black md:mx-0 mt-6">
        <form
          @submit.prevent="submit"
          class="relative w-full max-w-full text-black md:mx-auto lg:max-w-2xl xl:max-w-3xl"
        >
          <SearchInput
            v-model="form.query"
            @submit="submit"
            :disabled="isWaiting"
            :placeholder="placeholder"
            :error="errors.hasOwnProperty('question')"
          />
        </form>
        <div
          class="relative py-3 text-center text-xs leading-4 text-gray-600 md:px-16 md:py-4"
        >
          <span class="text-gray-600">
            Clipfinder.com knows all the podcasts until
            <time class="italic" datetime="{{ trainingDate }}"
              >{{ trainingDate.toLocaleDateString() }}</time
            >
          </span>
        </div>
      </div>

      <FailedMessage v-if="scrollState === ScrollState.Failed">
        We couldn't process your query due to an internal error. Please try again later.
      </FailedMessage>
    </div>
  </ContentLayout>
</template>

<script setup lang="ts">
import { onMounted, ref, computed, reactive } from 'vue';
import { storeToRefs } from 'pinia';
import { Head, router } from '@inertiajs/vue3';
import ContentLayout from '@/Layouts/ContentLayout.vue';
import ClipComponent from '@/Components/ClipComponent.vue';
import FailedMessage from '@/Components/FailedMessage.vue';
import { useUserStore, defineUserStore } from '@/Stores/user';
import { usePodcasterStore } from '@/Stores/podcaster';
import { useConversationStore, ScrollState } from '@/Stores/conversation';
import type { PodcasterResource, PodcastResource } from '@/types';
import SearchInput from '@/Components/SearchInput.vue';
import { defineClipStore } from '@/Stores/clip';
import { decodeHTML } from 'entities';
import { useMenuStore } from '@/Stores/menu';
import MediaMessageComponent from '@/Components/MediaMessageComponent.vue'

const menuStore = useMenuStore();

const { mobile } = storeToRefs(menuStore);

defineUserStore();

const props = defineProps<{
  podcaster: PodcasterResource;
  podcast: PodcastResource;
}>();

const placeholder = computed(() => {
  if (mobile.value) {
    return 'Ask a question...';
  }

  const decodedTitle = decodeHTML(props.podcast.title || '');

  return `Ask a question about: ${decodedTitle}...`;
});

const userStore = useUserStore();
const podcasterStore = usePodcasterStore();
const conversationStore = useConversationStore();
const clipStore = defineClipStore(props.podcast, props.podcaster);
const showSummary = ref(false);

const form = reactive({
  query: '',
});
const emit = defineEmits(['submit']);

const { loggedIn } = storeToRefs(userStore);
const errors = ref<{ [key: string]: string[] }>({});
const { scrollState } = storeToRefs(conversationStore);
const { trainingDate } = storeToRefs(podcasterStore);

const isWaiting = computed(
  () => scrollState.value === ScrollState.Requesting
);

const submit = async () => {
  // Early return if waiting or no podcaster is provided
  if (isWaiting.value || !props.podcaster) return;

  const podcasterId = props.podcaster.id;
  const question = form.query;
  form.query = '';

  if (!podcasterId) {
    handleError(new Error('Invalid podcaster ID'));
    return;
  }

  try {
    podcasterStore.selectPodcasters([podcasterId]);

    // Validate if the podcaster was successfully selected
    if (!podcasterStore.selectedPodcastersIds.includes(podcasterId)) {
      throw new Error('Failed to select podcaster');
    }

    // Send the message and handle conversation redirection
    const conversation = await conversationStore.sendMessage(
      question,
      true
    );
    if (conversation) {
      router.visit(
        route('clipfinder.conversations.show', [conversation])
      );
    }

    // Emit successful submission
    emit('submit', question);
  } catch (error: any) {
    handleError(error);
  }
};

// Helper function to handle errors
const handleError = (error: any) => {
  errors.value = error.errors || [];
};

onMounted(async () => {
  if (!props.podcaster?.id) return;

  // Initialize stores if user is logged in
  if (loggedIn.value) {
    await podcasterStore.fetchFollowedPodcasters();
  }

  // Set selected podcaster
  podcasterStore.selectPodcasters([props.podcaster.id]);

  // Fetch clips for the current podcast
  if (props.podcast?.id) {
    await clipStore.fetchEntityClips('podcast', props.podcast.id);
  }
});
</script>
