<template>
    <SlideOver title="Chat settings" :open="configurationSlideOver" @close="configurationSlideOver = false">
        <div class="mt-5 space-y-4 px-4 sm:px-6">
            <div>
                <label for="playback_rate" class="block text-sm font-medium leading-6 text-gray-900">Playback Rate</label>
                <div class="mt-2">
                    <PlaybackRateSelector />
                </div>
            </div>
        </div>

        <template #buttons>
            <button
                type="button"
                class="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                @click="configurationSlideOver = false"
            >
                Close
            </button>
        </template>
    </SlideOver>
</template>
<script setup lang="ts">
import SlideOver from '@/Components/SlideOver.vue'
import PlaybackRateSelector from '@/Components/PlaybackRateSelector.vue'
import { useConversationStore } from '@/Stores/conversation'
import { storeToRefs } from 'pinia'

const conversationStore = useConversationStore()
const { configurationSlideOver } = storeToRefs(conversationStore)
</script>
