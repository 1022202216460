<template>
    <Head :title="headTitle()" />
    <div class="mx-auto max-w-4xl">
        <div class="flex justify-center">
            <div class="flex items-start gap-4">
                <div class="flex flex-col items-center">
                    <div class="relative">
                        <!-- Create a placeholder div with fixed dimensions when no image -->
                        <div v-if="!podcaster?.picture_url"
                             class="inline-block size-20 rounded-full bg-gray-200 md:size-32">
                        </div>
                        <a v-else
                           :key="podcaster.id"
                           :href="route('clipfinder.podcasters.show', podcaster.slug)">
                            <picture
                                class="inline-block size-20 rounded-full bg-gray-400 ring-2 ring-gray-100 md:size-32">
                                <source
                                    class="size-20 rounded-full md:size-32"
                                    :srcset="podcaster.webp_picture_url ?? undefined"
                                    type="image/webp"
                                    height="160"
                                    width="160"
                                />
                                <img
                                    class="size-20 rounded-full md:size-32"
                                    :src="podcaster.picture_url"
                                    :alt="podcaster.name"
                                    height="160"
                                    width="160"
                                    loading="lazy"
                                />
                            </picture>
                        </a>

                        <!-- Button container with absolute positioning -->
                        <div class="absolute -bottom-5 left-1/2 -translate-x-1/2 transform">
                            <FollowButton
                                v-if="podcaster && loggedIn"
                                :podcaster-id="podcaster.id"
                                :initial-is-following="isFollowing(podcaster.id)"
                                :is-loading="isLoadingFollowState"
                                @follow-toggled="handleFollowToggle"
                                class="text-xs px-2 py-1"
                            />
                        </div>
                    </div>
                </div>

                <PageTitle v-if="!isSeemoreView" class="mt-4">
                    Start listening to {{ totalPodcasts }}
                    <br />
                    <PageTitleHighlight v-if="podcaster">{{ podcaster.name }}</PageTitleHighlight>
                    <br />
                    <span v-if="selectedCategory">{{ selectedCategory.name }}</span> podcasts
                </PageTitle>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted, watch } from 'vue'
import PageTitleHighlight from '@/Pages/Podcaster/PageTitleHighlight.vue'
import PageTitle from '@/Pages/Podcaster/PageTitle.vue'
import FollowButton from '@/Components/FollowButton.vue'
import type { PodcasterResource, CategoryResource } from '@/types'
import { storeToRefs } from 'pinia'
import { usePodcasterStore } from '@/Stores/podcaster'
import { definePodcastStore } from '@/Stores/podcast'
import { useUserStore } from '@/Stores/user'
import { ScrollState } from '@/Enum/ScrollState'
import { Head } from '@inertiajs/vue3'

const headTitle = () => {
    return [window.location.pathname.endsWith(props.podcaster.slug) ? '' : props.totalPodcasts, props.podcaster?.name, ' Podcasts'].join(' ')
}

const props = defineProps<{
    podcaster?: PodcasterResource
    totalPodcasts: number
    selectedCategory?: CategoryResource
    isSeemoreView?: boolean
    invisiblePodcastersCount: number
    isLoadingFollowState?: boolean
}>()

const podcasterStore = usePodcasterStore()
podcasterStore.selectPodcasters(props.podcaster?.id ? [props.podcaster.id] : [])

const store = definePodcastStore(props.podcaster)
const { totalPodcasts } = storeToRefs(store)
const { loggedIn } = storeToRefs(useUserStore())
const { followedPodcasters } = storeToRefs(podcasterStore)
const isLoadingFollowState = ref(false)

const isFollowing = (podcasterId: string) => {
  return followedPodcasters.value.some(p => p.id === podcasterId) || false
}


const handleFollowToggle = async (podcasterId: string) => {
    isLoadingFollowState.value = true;
    try {
        await podcasterStore.toggleFollowPodcaster(podcasterId);

        // Force refresh of followed podcasters list
        await podcasterStore.fetchFollowedPodcasters();
    } catch (e: any) {
        ScrollState.Failed;
    }
    finally {
        isLoadingFollowState.value = false;
    }
}

const fetchInitialFollowState = async () => {
    if (!loggedIn.value || !props.podcaster?.id) return;

    isLoadingFollowState.value = true;
    try {
        await podcasterStore.fetchFollowedPodcasters();

        // Ensure we have a valid array even if empty
        if (!followedPodcasters.value) {
            followedPodcasters.value = [];
        }
    } catch (error) {
        ScrollState.Failed;
    } finally {
        isLoadingFollowState.value = false;
    }
}

onMounted(() => {
    fetchInitialFollowState();
});

// Watch for podcaster changes or login state changes
watch(
    [() => props.podcaster?.id, () => loggedIn.value],
    async ([newId, newLoggedIn]) => {
        if (newId && newLoggedIn) {
            await fetchInitialFollowState();
        }
    }
);
</script>
