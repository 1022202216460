<template>
    <div class="flex justify-center w-full" ref="timelineContainer">
        <div class="sm:max-w-xl lg:w-[36rem]">
            <div v-for="(item, index) in timelineItems" :key="item.type + '-' + item.data.id" class="timeline-item">
                <div v-if="(item.data.podcast ? item.data.podcast.activeGuests.length > 0 : item.data.activeGuests.length > 0) && (showAllClips || !(item.type == 'clip' && !shouldShowMessage(item, index)))">
                    <!-- Render clip -->
                    <template v-if="item.type === 'clip'">
                        <MediaMessageComponent
                            type="clip"
                            :clip="item.data"
                            :showMessage="shouldShowMessage(item, index)"
                            :message="item.data.message.original_question"
                            :messageAvatar="isFromUser(item.data) ? user.avatar_url : (isTopic(item.data) ? 'topic' : 'anonymous')"
                            :messageDate="item.data.podcast?.date"
                            :messageSenderName="isFromUser(item.data) && getUserName(user?.id, user?.name) ? getUserName(user?.id, user?.name) : null"
                            :hideAvatarName="shouldHideAvatarName(item, index)"
                        />
                    </template>

                    <!-- Render podcast -->
                    <template v-else-if="item.type === 'podcast'">
                        <MediaMessageComponent
                            type="podcast"
                            :podcast="item.data"
                            :message="getCustomMessage(item)"
                            messageAvatar="podcast"
                            :messageDate="item.data.date"
                            :hideFullSummary="true"
                        />
                    </template>
                </div>
            </div>

            <div ref="endOfTimelineScroller"></div>
            <ClipLoader v-if="isLoading" title="Loading more items..." description="Traversing the timeline to provide more results." />
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { storeToRefs } from 'pinia'
import { useUserStore } from '@/Stores/user'
import { useInfiniteScroll, OverscrollDirection, type OverscrollOptions } from '@/Composables/useScrollEvents'
import ClipLoader from '@/Components/ClipLoader.vue'
import { useCategoryStore } from '@/Stores/category'
import { useTimelineStore } from '@/Stores/timeline'
import MediaMessageComponent from '@/Components/MediaMessageComponent.vue'
import type { PodcasterResource } from '@/types'

const userStore = useUserStore()
const { user } = storeToRefs(userStore)

const categoryStore = useCategoryStore()
const { selectedCategory } = storeToRefs(categoryStore)

const timelineStore = useTimelineStore()
const { timelineItems, isLoading } = storeToRefs(timelineStore)

const timelineContainer = ref<HTMLElement | null>(null)
const endOfTimelineScroller = ref<HTMLElement | null>(null)

const props = defineProps<{
    podcaster?: string,
    showAllClips?: boolean,
    scoreThreshold?: number,
    hideWhenEmpty?: boolean,
}>()

const isFromUser = (item) => {
    return item.user_id == user?.value?.id && item.user_id != null
}

const isTopic = (clip) => {
    return clip.is_topic
}

const hasSameQuestion = (item, prevItem) => {
    if (!prevItem || item.type !== 'clip' || prevItem.type !== 'clip') {
        return false
    }
    return item.data.message.id === prevItem.data.message.id
}

const hasSamePodcast = (item, prevItem) => {
    if (!prevItem || item.type !== 'clip' || prevItem.type !== 'clip') {
        return false
    }
    return item.data.podcast.id === prevItem.data.podcast.id
}

/**
 * Determine if we should show the message for a clip based on the following rules:
 * 1. Always show message for the first clip in the timeline
 * 2. Show message if this clip has a different question than the previous clip
 */
const shouldShowMessage = (item, index) => {
    // Always show message for first item
    if (index === 0) return true

    const prevItem = timelineItems.value[index - 1]

    // Show message if previous item isn't a clip or has a different question
    return !hasSameQuestion(item, prevItem)
}

/**
 * Determine if we should hide the avatar name/stack for a clip based on the following rules:
 * 1. Always show avatar for the first clip in the timeline
 * 2. If both clips have the same podcast AND same message, hide avatar for all but the first
 * 3. If both clips have the same podcast BUT different messages, show the avatar
 */
const shouldHideAvatarName = (item, index) => {
    // Always show for first item
    if (index === 0) return false

    const prevItem = timelineItems.value[index - 1]

    // If same podcast AND same question, hide avatar name
    if (hasSamePodcast(item, prevItem) && hasSameQuestion(item, prevItem)) {
        return true
    }

    // Otherwise show it
    return false
}

const loadMoreItems = () => {
    timelineStore.fetchTimelineItems(props.podcaster, props.scoreThreshold, props.hideWhenEmpty)
}

const isCurrentUser = (authorId: string | undefined) => {
    return user.value?.id == authorId
}

const getUserName = (userId: string | undefined, userName: string | undefined) => {
    if (userName === null || userName === undefined) {
        return null
    } else if (isCurrentUser(userId)) {
        return 'You'
    } else {
        return userName
    }
}

const formatGuestsList = (guests) => {
    if (!guests || guests.length === 0) {
        return 'Podcaster'
    }

    if (guests.length === 1) {
        return guests[0].name
    }

    const guestNames = guests.map(guest => guest.name)
    const lastGuest = guestNames.pop()

    return `${guestNames.join(', ')} and ${lastGuest}`
}

const getCustomMessage = (item: any) => {
    if (item.type == 'podcast') {
        const resource = item.data as PodcasterResource

        const activeGuests = resource.activeGuests
        const formattedActiveGuests = formatGuestsList(activeGuests)
        const categories = resource.categories.map(category => category.name)
        const duration = resource.duration
        const formattedDuration = duration >= 3600
            ? `${Math.floor(duration / 3600)}h ${Math.floor((duration % 3600) / 60)} min.`
            : `${Math.floor(duration / 60)} minutes`

        return 'New ' + formattedActiveGuests + ' video' + (categories && categories.length > 0 ? ' about ' + categories.join(', ') : '') + ' | ' + formattedDuration
    }

    return ""
}

onMounted(() => {
    timelineStore.resetTimeline()
    loadMoreItems()

    if (timelineContainer.value && endOfTimelineScroller.value) {
        useInfiniteScroll(timelineContainer.value, endOfTimelineScroller.value, loadMoreItems, {
            direction: OverscrollDirection.bottom,
            rootMargin: '150px',
        } as OverscrollOptions)
    }
})
</script>

<style scoped>
.timeline-item {
    position: relative;
}
</style>
