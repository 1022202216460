<template>
    <div v-if="guests && guests.length > 0" class="mb-2 flex items-center">
        <div class="relative inline-flex flex-shrink-0 text-slate-500">
            <div class="isolate flex -space-x-3 overflow-hidden">
                <span
                    class="hover:cursor-pointer"
                    @click="onGuestClick(guest)"
                    v-for="guest in avatarGuests"
                    :key="'guestAvatars-' + guest.id"
                >
                    <img
                        :alt="guest.name"
                        class="relative z-30 inline-block size-7 rounded-full ring-2 ring-white"
                        height="32"
                        width="32"
                        :src="guest.picture_url"
                    />
                </span>
            </div>
        </div>
        <div class="prose ml-2 text-sm font-bold">
            <span
                class="font-bold no-underline hover:cursor-pointer"
                @click="onGuestClick(guest)"
                v-for="(guest, key) in visibleGuests"
                :key="'visibleGuests-' + guest.id"
            >
                {{ guest.name }}<span v-if="key != visibleGuests.length - 1">, </span>
            </span>
            <span v-if="hiddenGuestCount > 0"> (+{{ hiddenGuestCount }})</span>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import type { PodcasterResource } from '@/types'

const props = defineProps({
    guests: {
        type: Array as () => PodcasterResource[],
        default: () => []
    },
    visibleGuestsCount: {
        type: Number,
        default: 3
    }
})

const emit = defineEmits<{
    (e: 'guest-click', guest: PodcasterResource): void
}>()

const avatarGuests = computed(() => {
    return props.guests.filter((guest: PodcasterResource) => 
        null !== guest.picture_url && '' !== guest.picture_url
    )
})

const visibleGuests = computed(() => {
    return props.guests.slice(0, props.visibleGuestsCount)
})

const hiddenGuestCount = computed(() => {
    return Math.max(0, props.guests.length - props.visibleGuestsCount)
})

const onGuestClick = (guest: PodcasterResource) => {
    emit('guest-click', guest)
}
</script> 