// Extend Window interface to include YouTube callback is now in env.d.ts

export function useYoutubeScripts(): Promise<void> {
    return new Promise((resolve, reject) => {
        // If YT is already defined and ready, resolve immediately
        if (typeof YT !== 'undefined' && YT.Player) {
            resolve();
            return;
        }

        // Set up a global callback that YouTube will call when ready
        window.onYouTubeIframeAPIReady = () => {
            resolve();
        };

        // Check if script is already in the document
        const existingScript = document.head.querySelector('[src="https://www.youtube.com/player_api"]');
        if (existingScript) {
            // Script exists but hasn't loaded - we'll wait for the callback
            return;
        }

        // Create and append the script
        try {
            const tag = document.createElement('script');
            tag.setAttribute('src', 'https://www.youtube.com/player_api');
            tag.setAttribute('type', 'text/javascript');
            document.head.appendChild(tag);
        } catch (error) {
            reject(error);
        }
    });
}
