<template>
    <div
        class="sticky top-0 z-10 mb-1.5 flex h-10 items-center justify-between bg-white/95 pb-4 pt-4 font-semibold shadow md:h-14 md:shadow-none lg:px-2"
    >
        <div class="flex items-center">
            <button
                type="button"
                @click="toggle"
                class="inline-flex h-10 w-10 items-center justify-center rounded-md hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white active:opacity-50 md:hidden dark:hover:text-white"
            >
                <span class="sr-only">Open sidebar</span>
                <svg class="h-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M3 8C3 7.44772 3.44772 7 4 7H20C20.5523 7 21 7.44772 21 8C21 8.55228 20.5523 9 20 9H4C3.44772 9 3 8.55228 3 8ZM3 16C3 15.4477 3.44772 15 4 15H14C14.5523 15 15 15.4477 15 16C15 16.5523 14.5523 17 14 17H4C3.44772 17 3 16.5523 3 16Z"
                        fill="currentColor"
                    ></path>
                </svg>
            </button>
            <slot name="left"></slot>
        </div>
        <div class="flex gap-2 pr-1">
            <slot name="right"></slot>
        </div>
    </div>
</template>
<script setup lang="ts">
import { useMenuStore } from '@/Stores/menu'

const { toggle } = useMenuStore()
</script>
