<template>
    <Popover as="div" class="relative">
        <PopoverButton

            class="relative inline-flex w-full cursor-default items-center pr-7 text-left text-xs text-gray-500 hover:text-gray-800 focus:text-gray-800 focus:outline-none sm:leading-6"
        >
            <CalendarIcon class="mr-1.5 size-3.5" />
            <span class="block truncate">{{ rangeName }}</span>
            <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon class="size-3.5 text-gray-400" aria-hidden="true" />
            </span>
        </PopoverButton>

        <transition
            enter-active-class="transition duration-200 ease-out"
            enter-from-class="translate-y-1 opacity-0"
            enter-to-class="translate-y-0 opacity-100"
            leave-active-class="transition duration-150 ease-in"
            leave-from-class="translate-y-0 opacity-100"
            leave-to-class="translate-y-1 opacity-0"
        >
            <PopoverPanel
                class="absolute bottom-11 z-10 -translate-x-1/2 rounded-md bg-white py-1 text-base shadow-xl ring-1 ring-black ring-opacity-10 focus:outline-none sm:text-sm"
            >
                <div class="px-4 py-2 text-center">
                    <CalendarIcon class="mx-auto size-6 text-primary" />
                    <label for="date_range" class="mt-2 block text-sm font-medium leading-6 text-gray-900">Search window</label>
                    <div class="text-xs text-gray-600">Clips will only be searched between these two years.</div>
                    <div class="mt-4 flex items-center space-x-2">
                        <SelectBox v-model="fromYear" :options="years" direction="top">
                            {{ fromYear }}
                        </SelectBox>
                        <div>-</div>
                        <SelectBox v-model="tillYear" :options="years" direction="top">
                            {{ tillYear }}
                        </SelectBox>
                    </div>
                </div>
            </PopoverPanel>
        </transition>
    </Popover>
</template>

<script setup lang="ts">
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { CalendarIcon, ChevronUpDownIcon } from '@heroicons/vue/24/outline'
import SelectBox from '@/Components/SelectBox.vue'
import { useConversationStore } from '@/Stores/conversation'
import { computed, type ComputedRef, nextTick, onMounted, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { usePodcasterStore } from '@/Stores/podcaster'

const conversationStore = useConversationStore()
const { patchConversation } = conversationStore
const { fromYear, tillYear } = storeToRefs(conversationStore)

const podcasterStore = usePodcasterStore()
const { yearRange } = storeToRefs(podcasterStore)

// Watch changes to year range, the constraints might change if we change the podcaster selection
watch(yearRange, () => {
    if (!tillYear.value || tillYear.value > yearRange.value.till) {
        tillYear.value = yearRange.value.till
    }

    if (!fromYear.value || fromYear.value < yearRange.value.from) {
        fromYear.value = yearRange.value.from
    }
})

const years: ComputedRef<{ value: number; name: string }[]> = computed(() => {
    return Array.from({ length: yearRange.value.till - yearRange.value.from + 1 }, (_, i) => yearRange.value.till - i).map((year: number) => ({
        value: year,
        name: `${year}`,
    }))
})

// Prevent looking backwards
watch(tillYear, () => {
    if (!fromYear.value || tillYear.value < fromYear.value) {
        fromYear.value = tillYear.value
    }

    nextTick(() => submit())
})

// Prevent looking forwards
watch(fromYear, () => {
    if (!fromYear.value || tillYear.value < fromYear.value) {
        tillYear.value = fromYear.value
    }

    nextTick(() => submit())
})

const submit = async () => {
    await patchConversation({ from_year: fromYear.value, till_year: tillYear.value })
}

const rangeName = computed(() => {
    if (fromYear.value === tillYear.value) {
        return fromYear.value
    }

    return `${fromYear.value} - ${tillYear.value}`
})
</script>
