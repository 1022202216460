<template>
    <div class="flex flex-col items-center">
        <div class="relative">
            <PodcasterCard :podcaster="podcaster" />

            <!-- Button container with absolute positioning -->
            <div class="absolute bottom-10 left-1/2 -translate-x-1/2 transform">
                <FollowButton
                    v-if="podcaster && loggedIn"
                    :podcaster-id="podcaster.id"
                    :initial-is-following="isFollowing(podcaster.id)"
                    :is-loading="isLoadingFollowState"
                    @follow-toggled="handleFollowToggle"
                    class="text-xs px-2 py-1"
                />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import FollowButton from '@/Components/FollowButton.vue'
import type { PodcasterResource } from '@/types'
import { storeToRefs } from 'pinia'
import { usePodcasterStore } from '@/Stores/podcaster'
import { useUserStore } from '@/Stores/user'
import { ScrollState } from '@/Enum/ScrollState'
import PodcasterCard from '@/Components/PodcasterCard.vue'


const props = defineProps<{
    podcaster?: PodcasterResource
    isLoadingFollowState?: boolean
}>()

const podcasterStore = usePodcasterStore()

const { loggedIn } = storeToRefs(useUserStore())
const { followedPodcasters } = storeToRefs(podcasterStore)
const isLoadingFollowState = ref(false)

const isFollowing = (podcasterId: string) => {
  return followedPodcasters.value.some(p => p.id === podcasterId) || false
}

const handleFollowToggle = async (podcasterId: string) => {
    isLoadingFollowState.value = true;
    try {
        await podcasterStore.toggleFollowPodcaster(podcasterId);

        // Force refresh of followed podcasters list
        await podcasterStore.fetchFollowedPodcasters();
    } catch (e: any) {
        ScrollState.Failed;
    }
    finally {
        isLoadingFollowState.value = false;
    }
}

const fetchInitialFollowState = async () => {
    if (!loggedIn.value || !props.podcaster?.id) return;

    isLoadingFollowState.value = true;
    try {
        await podcasterStore.fetchFollowedPodcasters();

        // Ensure we have a valid array even if empty
        if (!followedPodcasters.value) {
            followedPodcasters.value = [];
        }
    } catch (error) {
        ScrollState.Failed;
    } finally {
        isLoadingFollowState.value = false;
    }
}

// Watch for podcaster changes or login state changes
watch(
    [() => props.podcaster?.id, () => loggedIn.value],
    async ([newId, newLoggedIn]) => {
        if (newId && newLoggedIn) {
            await fetchInitialFollowState();
        }
    }
);
</script>
